/*
 * @Author: your name
 * @Date: 2021-04-13 10:22:59
 * @LastEditTime: 2022-03-11 17:30:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \vinda-phone\src\router\index.js
 */
import Vue from "vue";
import VueRouter from "vue-router";
import examList from '@/views/examlist/index.vue'
import examQuestion from '@/views/examcentre/graduallyQuestionPaper.vue'
import examPaper from '@/views/examcentre/index.vue'
import { init } from '../plugin/signalr'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: '/login',
  },
  {
    path: "/home",
    name: 'home',
    component: () => import('../views/home/index.vue'),
  },
  {
    path: "/login",
    name: 'login',
    // component: () => import('../views/login'),
    component: () => import('../views/login-old.vue'),
  },
  {
    path: "/thirdLogin",
    name: 'thirdLogin',
    component: () => import('../views/thirdLogin.vue'),
  },
  {
    path: '/exam/paper',
    name: 'examPaper',
    // component: examQuestion
    component: examPaper
  },
  {
    path: '/exam/paper/detail',
    name: 'examPaperDetail',
    component: () => import('../views/examcentre/detail'),
  },
  {
    path: '/exam/grades/scoring-analysis',
    name: 'scoringAnalysis',
    component: () => import('../views/examcentre/scoringAnalysis'),
  },
  {
    path: '/exam/question',
    name: 'mainPage',
    component: examQuestion
  },
  {
    path: '/exam/list',
    name: 'examList',
    component: examList
  },
  {
    path: '/exam/record',
    name: 'record',
    component: () => import('../views/examlist/record.vue'),
  },
  {
    path: '/newsList/list',
    name: 'newsList',
    component: () => import('../views/newsList/index'),
  },
  {
    path: '/newsList/detail',
    name: 'newsList',
    component: () => import('../views/newsList/detail'),
  },
  {
    path: "*",
    redirect: '/login',
  },
];

const router = new VueRouter({
  // mode: 'history',
  routes
});
router.beforeEach((to, from, next) => {
  if (to.query.token) {
    if (to.path != '/login') {
      sessionStorage.setItem('token', to.query.token);
    } else {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('baseDetail');
    }
  }
  if (to.path != '/login') {
    if (to.path == '/thirdLogin') {
      next()
      return
    }
    if (sessionStorage.getItem('token')) {
      if (!Vue.prototype.signalr) {
        init()
        // console.warn(Vue.prototype.signalr);
      } else {
      }
      next()
    } else {
      router.push('/login')
    }
  } else {
  }
  next()
})
export default router;
