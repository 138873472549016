<template>
  <!-- <div class="publish"> -->
  <div>
    <video style="margin: 0 auto" ref="video" v-show="!imgSrc"></video>
    <img class="head-img" v-if="imgSrc" :src="imgSrc" alt="" />
    <canvas
      style="display: none"
      width="387"
      height="350"
      id="canvasCamera"
    ></canvas>
    <div class="main-foot">
      <div
        class="btn"
        style="margin-right: 20px"
        v-if="!imgSrc"
        @click="setImage"
      >
        <img
          style="margin-right: 15px"
          src="../../assets/components/upload-icon.png"
          alt=""
        />
        <div>拍照</div>
      </div>
      <div class="btn" style="margin-right: 20px" v-else @click="OpenCamera">
        <img
          style="margin-right: 15px"
          src="../../assets/components/upload-icon.png"
          alt=""
        />
        <div>重拍</div>
      </div>
      <div
        v-if="imgSrc"
        style="margin-right: 20px"
        @click.stop="upload"
        class="btn"
      >
        <img
          style="margin-right: 15px"
          src="../../assets/components/upload-icon.png"
          alt=""
        />
        <div>确认上传</div>
      </div>
      <div class="btn" style="width: 80px" @click="handleClose">返回</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {},
  data() {
    return {
      isFace: false,
      isOk: false,
      mediaStreamTrack: {},
      video_stream: "", // 视频stream
      imgSrc: "", // 拍照图片
      canvas: null,
      context: null,
      isDiscern: false,
      faceInterval: null,
      userInfo: {
        extraProperties: {},
      },
    };
  },
  created() {
    (this.isOk = false), (this.isDiscern = false), (this.isFace = false);
    if (sessionStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    } else {
      userApi
        .getUserInfo(sessionStorage.getItem("userId"))
        .then((result) => {
          sessionStorage.setItem("userInfo", JSON.stringify(result.data));
          this.userInfo = result.data;
          if (!localStorage.getItem("examList")) {
            localStorage.setItem("examList", []);
          }
        })
        .catch((err) => {
          console.warn(err);
        });
    }
  },
  mounted() {
    // 进入页面 自动调用摄像头
    // this.getCamera();
    //
    // if (this.showFace) {
    this.OpenCamera();
    // }
  },
  destroyed() {
    this.CloseCamera();
  },
  methods: {
    upload() {
      let fileRelationId = "";
      if (sessionStorage.getItem("userId")) {
        fileRelationId = sessionStorage.getItem("userId");
      }
      let formData = new FormData();
      // this.formData = formData;
      formData.append("file", this.dataURLtoFile(this.imgSrc, fileRelationId));
      this.formData = formData;
      console.warn("this.formData", this.formData);
      axios
        .post(
          `/api/app/file/upload-file-system?fileTypeCode=user&fileRelationId=${fileRelationId}`,
          this.formData
        )
        .then((res) => {
          this.avatar = res.data;
          // this.$message.success('上传成功');
          axios
            .post(
              `/api/base/user/faceimage/${fileRelationId}?blobName=${this.avatar.blobName}`
            )
            .then((res) => {
              if (res) {
                this.$message.success("照片上传成功");
                this.userInfo.extraProperties.FaceImage = this.avatar.blobName;
                sessionStorage.setItem(
                  "userInfo",
                  JSON.stringify(this.userInfo)
                );
                this.closeAll();
              } else {
                this.$message.success("照片上传失败，请重新上传或联系管理员");
              }
            })
            .catch((err) => {
              this.$message.error(err);
            });
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    handleClose() {
      this.CloseCamera();
      this.$emit("closepop"); //通知父组件改变。
    },
    closeAll() {
      this.$emit("closeAll"); //通知父组件改变。
      this.CloseCamera();
    },
    // 调用打开摄像头功能
    getCamera() {
      // 获取 canvas 画布
      this.canvas = document.getElementById("canvasCamera");
      this.context = this.canvas.getContext("2d");
      // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      navigator.mediaDevices
        .getUserMedia({
          video: true,
        })
        .then((stream) => {
          // 摄像头开启成功
          this.mediaStreamTrack =
            typeof stream.stop === "function" ? stream : stream.getTracks()[0];
          this.video_stream = stream;
          this.$refs.video.srcObject = stream;
          this.$refs.video.play();

          // this.faceInterval = setInterval(() => {
          //     this.setImage();
          // }, this.detail.openCameraInvigilateInterval * 60000);
        })
        .catch((err) => {
          console.log(err);
          console.error("摄像头开启失败，请检查摄像头是否可用！");
          this.$message.error("摄像头开启失败，请检查摄像头是否可用！");
        });
    },
    // 拍照 绘制图片
    setImage() {
      console.log("拍照");
      // 点击canvas画图
      // 获取图片base64链接
      this.context.drawImage(this.$refs.video, 0, 0, 480, 320);
      const image = this.canvas.toDataURL("image/png");
      this.imgSrc = image;

      this.$emit("refreshDataList", this.imgSrc);
    },
    dataURLtoFile(dataUrl, filename) {
      let arr = dataUrl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        //         undefined;

        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    // 打开摄像头drawImage
    OpenCamera() {
      console.log("打开摄像头");
      this.imgSrc = "";
      this.getCamera();
    },
    // 关闭摄像头
    CloseCamera() {
      console.log("关闭摄像头");
      this.MediaStreamTrack && this.MediaStreamTrack.stop();
      this.$refs.video.srcObject.getTracks()[0].stop();
    },
  },
};
</script>

<style lang="scss" scoped>
video {
  // width: 480px;
  // height: 320px;
  // width: 300px;
  // height: 250px;
  width: 387px;
  height: 300px;
}
.head-img {
  height: 300px;
  object-fit: cover;
  margin: 0 auto;
  display: block;
}

.main-foot {
  width: 100%;
  display: flex;
  justify-content: center;
  .btn {
    width: 145px;
    height: 57px;
    background: #f8f8f8;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.customClass {
  width: 510px;
}
.row {
  margin-bottom: 8px;
}
</style>