<!--
 * @Author: your name
 * @Date: 2021-10-14 14:51:56
 * @LastEditTime: 2022-03-11 17:36:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web-exam\src\App.vue
-->

<template>
    <div id="app">
        <router-view />
        <Message v-if="!isLogin"></Message>
        <Copyright></Copyright>
    </div>
</template>
<script>
import Copyright from '@/components/Copyright.vue';
import Message from '@/components/message';
export default {
    components: { Copyright, Message },
    data() {
        return {
            isLogin: false,
            navList: [{ name: '首页' }, { name: '学习中心' }, { name: '考试中心' }, { name: '资讯中心' }, { name: '调查问卷' }, { name: '职业发展' }, { name: '审批中心' }],
        };
    },
    created() {
        if (this.$route.path == '/login' || this.$route.path == '/' || this.$route.path == '/thirdLogin ') {
            this.isLogin = true;
        } else if (this.$route.path == '/exam/paper' || this.$route.path == '/exam/question') {
            this.isLogin = true;
        } else {
            this.isLogin = false;
        }
    },
    watch: {
        $route: {
            handler(newVal, oldVal) {
                if (newVal.path == '/login' || newVal.path == '/' || newVal.path == '/thirdLogin ') {
                    this.isLogin = true;
                } else if (newVal.path == '/exam/paper' || newVal.path == '/exam/question') {
                    this.isLogin = true;
                } else {
                    this.isLogin = false;
                }
                this.$forceUpdate();
            },
        },
    },
    mounted() {
            var str = navigator.userAgent
            var ipad = str.match(/(iPad).*OS\s([\d_]+)/)
            var isIphone = !ipad && str.match(/(iPhone\sOS)\s([\d_]+)/)
            var isAndroid = str.match(/(Android)\s+([\d.]+)/)
            // 或者单独判断iphone或android 跳转移动端
            if (isIphone || isAndroid || ipad) {
            
               window.location.href = window.location.protocol+"//"+window.location.hostname+':8081'
                //this.$router.replace('/404')
            } 
    },
    
};
//1.
</script>
<style lang="scss">
//   .headr {
//     width: 100%;
//     height: 60px;
//     background: #ffffff;
//     box-shadow: 0px 4px 10px 0px #f2f3f6;
//     // margin-bottom: 43px;
//   }
.sidebar-popper {
    border-radius: 15px !important;
    right: 80px !important;
}
html,
body,
ul,
p {
    margin: 0;
    padding: 0;
}
html,
body,
#app {
    width: 100%;
    height: 100%;
    // background-color: #e1f2fa;
    button {
        cursor: pointer;
    }
    // overflow: hidden;
}
</style>
// <style lang="scss" scoped>
// .el-dropdown-menu {
//     border-radius: 10px;
// }
// .el-dropdown-menu__item {
//     font-size: 16px;
//     font-family: PingFangSC, PingFangSC-Medium;
//     font-weight: 500;
//     text-align: center;
//     min-width: 120px;
//     line-height: 42px;
// }
// .list-nav {
//     z-index: 99;
//     height: 86px;
//     display: flex;
//     position: absolute;
//     justify-content: space-between;
//     width: 100%;
//     .nav-left {
//         padding-left: 80px;
//         height: 100%;
//         display: flex;
//         align-items: center;
//         img {
//             width: 162px;
//             height: 30px;
//         }
//         flex: 1;
//     }
//     .nav-center {
//         display: flex;
//         align-items: center;
//         height: 100%;
//         flex: 7;
//         .nav-menu {
//             display: flex;
//             width: 100%;
//             height: 86px;
//             align-items: center;
//             justify-content: flex-end;
//             .menu-item {
//                 // padding: 30px 45px;
//                 font-size: 16px;
//                 font-family: PingFangSC, PingFangSC-Medium;
//                 font-weight: 500;
//                 color: #1e2044;
//                 display: flex;
//                 justify-content: center;
//                 margin-right: 45px;
//                 align-items: center;
//                 .pull-down {
//                     width: 8px;
//                     height: 4px;
//                     margin-left: 10px;
//                 }
//             }
//             // background-color:pink;
//         }
//     }
//     .nav-right {
//         display: flex;
//         .search-item {
//             width: 104px;
//             height: 46px;
//             background: #1fa3ff;
//             border-radius: 25px;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             font-size: 14px;
//             font-family: PingFangSC, PingFangSC-Medium;
//             font-weight: 500;
//             color: #ffffff;
//             margin-right: 20px;
//             img {
//                 width: 18px;
//                 height: 18px;
//                 margin-right: 8px;
//             }
//         }
//         .user-portrait {
//             width: 46px;
//             height: 46px;
//             border-radius: 50%;
//             overflow: hidden;
//             margin-right: 130px;
//         }
//     }
// }
//
</style>