/*
 * @Author: your name
 * @Date: 2021-07-09 14:22:42
 * @LastEditTime: 2021-12-20 10:37:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \vinda-map-pc\src\plugin\element.js
 */
import Vue from 'vue';
import {
  Form,
  FormItem,
  Input,
  Select,
  Option,
  Upload,
  OptionGroup,
  Checkbox,
  CheckboxButton,
  Slider,
  CheckboxGroup,
  Radio,
  RadioGroup,
  Button,
  ButtonGroup,
  Switch,
  Row,
  Col,
  Aside,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Tooltip,
  Popover,
  Table,
  TableColumn,
  Pagination,
  Tabs,
  TabPane,
  Dialog,
  DatePicker,
  TimeSelect,
  TimePicker,
  MessageBox,
  Message,
  Rate,
  Loading,
  Scrollbar,
  Tag,
  InfiniteScroll,
  Progress,
  Badge,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Card,
  Carousel,
  CarouselItem,
} from 'element-ui';

Vue.use(Form);
Vue.use(Slider);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Card);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Upload);
Vue.use(Badge);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Select);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Row);
Vue.use(Col);
Vue.use(Aside);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Tooltip);
Vue.use(Popover);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Dialog);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(TimePicker);
Vue.use(Rate);
Vue.use(Scrollbar);
Vue.use(Tag);
Vue.use(Switch);
Vue.use(Progress);
Vue.use(InfiniteScroll);
Vue.use(Loading.directive);
Object.assign(Vue.prototype, {
  $ELEMENT: { zIndex: 999 }, // size: 'small',
  $message: Message,
  $messageBox: MessageBox,
  $confirm: MessageBox.confirm,
  $alert: MessageBox.alert,
  $prompt: MessageBox.prompt,
  $loading: Loading,
});
