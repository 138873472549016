import axios from 'axios'
import Vue from 'vue'
import { Message, Loading } from 'element-ui'
import qs from 'qs'
import router from './router';
import Cookie from 'js-cookie'

import { serverPrefix, loginPrefix } from '../config'
const xsrfHeaderName = 'Admin-Token'

axios.defaults = Object.assign(axios.defaults, {
  baseURL: serverPrefix,
  timeout: 300000
  // validateStatus (status) {}
})
const validateStatus = ({ response }) => {

  if (response.data && response.data.description) {
    if (response.data && response.data.code == "4010110002") {
      // window.location.href = '/'
    }
    else
      Vue.prototype.$message.error(response.data.description)
  } else {
    switch (response.status) {
      case 400:
        Vue.prototype.$message.error('请求参数错误')
        break
      case 401:
        Vue.prototype.$message.error('登录已过期或者未登录')
        router.push('/login')
        sessionStorage.clear();

        break
      case 403:
        Vue.prototype.$message.error({
          message: response.data.error.message,
          duration: 5000
        })
        break
      case 408:
        Vue.prototype.$message.error('客户端请求超时')
        break
      case 500:
        if (response.Message == "An error has occurred.") {
          Vue.prototype.$message.error('登录已过期或者未登录')
          sessionStorage.clear();

        } else {
          Vue.prototype.$message.error('服务器内部错误')
        }
        break
      case 504:
        Vue.prototype.$message.error('网关超时')
        break
      default:
        if (response.status < 200 && response.status >= 300) {
          Vue.prototype.$message.error('响应数据出错')
        }
    }
  }
}

const setAuthorization = (config) => {

  let Token = sessionStorage.getItem('token')

  if (Token) {
    // let userUid = sessionStorage.getItem("UserUid")
    Object.assign(config.headers, {
      Authorization: `Bearer ${Token}`,
      // "If-Match": userUid
    })
  }
}



/**
* 移出认证信息
* @param authType {AUTH_TYPE} 认证类型
*/

/**
* 检查认证信息
* @param authType
* @returns {boolean}
*/

// function getUserId(url) {
//   if (url.indexOf('/ApiVida/api/LearnAction/UserInfoAndLearnActive?UserUid')) {
//     let strUrl = url.slice((url.lastIndexOf('?') + 1))
//     let arr = strUrl.split("=")
//     return arr[1]
//   }
// }

const loadingService = {
  instance: null,
  open(config) {
    if (typeof config.loading === 'boolean' && !config.loading) return
    const params = {
      // lock: true,
      text: 'loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.8)'
    }
    if (typeof config.loading === 'object') {
      Object.assign(params, config.loading)
    }
    this.instance = Loading.service(params)
  },
  close() {
    if (this.instance) {
      this.instance.close()
    }
  }
}

// http请求拦截器
axios.interceptors.request.use((config) => {
  // 如果是上传文件，就转成formdata
  if (config.file) {
    Object.assign(config.headers, {
      'Content-Type': 'multipart/form-data'
    })
    config.transformRequest = (data) => {
      const formdata = new FormData()
      for (const o of Object.keys(data)) {
        formdata.append(o, data[o])
      }
      return formdata
    }
  }
  // 如果有token就在请求头带上
  setAuthorization(config)
  if (config.url != '/api/examservice/exam/monitor/cut-screen') {
    loadingService.open(config)
  } else {
    return config
  }
  return config
}, (error) => {

  loadingService.close()
  return Promise.reject(error)
})

// http响应拦截器
axios.interceptors.response.use((response) => {
  loadingService.close()
  return response
}, (error) => {
  loadingService.close()
  validateStatus(error)
  return Promise.reject(error)
})

axios.upload = (...arg) => {
  const url = arg[0]
  const data = arg[1] || {}
  const formdata = new FormData()
  for (const o of Object.keys(data)) {
    formdata.append(o, data[o])
  }
  const config = Object.assign({
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }, arg[2])
  return axios.post(url, formdata, config).then(response => response.data)
}

const install = (Vue) => {
  Object.assign(Vue.prototype, { axios })
}

function instancePosts(url, params) { // 登录
  var instance = axios.create({
    baseURL: loginPrefix
  })
  if (params.tenant && params.tenant.trim() != '') {
    url = url + "?__tenant=" + params.tenant
  }
  var data = qs.stringify(params)
  return new Promise((resolve, reject) => {
    instance.post(url, data)
      .then(response => {
        resolve(response.data)
      }, err => {
        if (err.response.status === 400) {
          console.warn(err.response);
          Vue.prototype.$message({
            message: err.response.data.error_description ? err.response.data.error_description : '用户名或密码错误',
            type: 'error',
            duration: 5 * 1000
          })
        } else {
          Vue.prototype.$message({
            message: err.error_description,
            type: 'error',
            duration: 5 * 1000
          })
        }
        reject(err)
      })
      .catch((error) => {
        Vue.prototype.$message({
          message: '登录异常',
          type: 'error',
          duration: 5 * 1000
        })
        reject(error)
      })
  })
}
function getPermissions(url, params) {
  let instance = axios.create({
    baseURL: serverPrefix
  })
  instance.defaults.headers.Authorization = `Bearer ${sessionStorage.getItem('token')}`
  // instance.defaults.headers.common['Authorization'] = `Bearer ${getToken()}` 
  return new Promise((resolve, reject) => {
    instance.get(url, {
      'params': params
    }).then(response => {
      resolve(response.data)
    }, err => {
      // Message({
      //   message: err.message,
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      reject(err)
    })
      .catch((error) => {
        reject(error)
      })
  })
}
export default install
export { axios, instancePosts, getPermissions }
