/*
 * @Author: your name
 * @Date: 2021-04-13 10:22:59
 * @LastEditTime: 2021-10-21 10:01:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \vinda-phone\src\plugins\vant.js
 */
import Vue from 'vue'
import {
    // Tabbar,
    // TabbarItem,
    Search,
    Icon,
    Swipe,
    SwipeItem,
    Rate,
    Field,
    Tab,
    Tabs,
    List,
    Progress,
    Button,
    NavBar,
    Popover,
    // Slider,
    Overlay,
    Toast,
    Dialog,
    Badge,
    Picker,
    Popup,
    Step,
    Steps,
    CountDown,
    // ActionSheet ,
    // Cell,
    // RadioGroup,
    // Radio
} from 'vant'
Vue.use(Step)
Vue.use(Steps)
Vue.use(Overlay)
Vue.use(CountDown)
Vue.use(Search)
Vue.use(Icon)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Rate)
Vue.use(Field)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(List)
Vue.use(Progress)
Vue.use(Button)
Vue.use(NavBar)
Vue.use(Popover)
Vue.use(Badge)
Vue.use(Toast)
Vue.use(Dialog)
Vue.use(Picker);
Vue.use(Popup);
// Vue.use(CountDown)
// Vue.use(ActionSheet)
// Vue.use(Cell)
// Vue.use(RadioGroup)
// Vue.use(Radio)
// Vue.use()
// Vue.use()

Object.assign(Vue.prototype, {
    $Toast: Toast,
    // $dialog: Dialog
});