<!--
 * @Author: your name
 * @Date: 2021-11-09 10:49:59
 * @LastEditTime: 2022-03-11 17:36:15
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \web-pc\src\components\Copyright.vue
-->
<template>
    <div v-if="!isLogin" :class="!isLogin && 'bg-color'">
        <div class="Copyright-modal">
            <span>{{ copyright }}</span>
        </div>
    </div>
</template>

<script>
import Api from '@/api/home';
export default {
    data() {
        return {
            copyright: '',
            token: '',
            isLogin: '',
        };
    },
    created() {
        if (this.$route.path == '/exam/paper' || this.$route.path == '/exam/question') {
            this.isLogin = true;
        }
    },
    watch: {
        $route: {
            handler(newVal, oldVal) {
                if (newVal.path == '/login' || newVal.path == '/exam/paper') {
                    this.isLogin = true;
                } else {
                    this.isLogin = false;
                    Api.getCopyright()
                        .then(res => {
                            console.warn(res);
                            res.data.forEach(item => {
                                if (item.name == 'Abp.Identity.User.CopyRight') {
                                    this.copyright = item.value;
                                    this.$forceUpdate()
                                }
                            });
                        })
                        .catch(err => {
                            console.warn(err);
                        });
                }
                this.$forceUpdate();
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.bg-color {
    background-color: #fdfeff;
}
.Copyright-modal {
    width: 1300px;
    margin: 0 auto;
    align-items: center;
    height: 80px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    // font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #a5a5a5;
    line-height: 22px;
}
</style>