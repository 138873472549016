<template>
  <!-- <div class="publish"> -->
  <div class="exam-face" :class="[!openFace && 'close']">
    <div class="face-title" @click="openFace = !openFace">
      <div class="title-text">考试监控</div>
      <img class="icon" src="../../assets/exam/switch.png" alt="" />
    </div>
    <div class="face-main">
      <video style="margin: 0 auto" ref="video"></video>
      <canvas
        style="display: none"
        width="387"
        height="350"
        id="canvasCamera"
      ></canvas>
    </div>
  </div>
</template>

<script>
import Api from "@/api/examList";
export default {
  props: {
    detail: {
      require: true,
      type: Object,
      default: {
        examName: "",
        beginTimeFormat: "",
        endTimeFormat: "",
        examTime: "",
        maxExamNum: "",
        examArrangeUid: "",
        examGradeUid: "",
        openCameraInvigilateInterval: 5,
        IsOpenFaceAuthentication: false,
      },
    },
  },
  data() {
    return {
      isFace: false,
      isOk: false,
      mediaStreamTrack: {},
      video_stream: "", // 视频stream
      imgSrc: "", // 拍照图片
      canvas: null,
      context: null,
      openFace: true,
      isDiscern: false,
      faceInterval: null,
    };
  },
  created() {
    (this.isOk = false), (this.isDiscern = false), (this.isFace = false);
  },
  mounted() {
    // 进入页面 自动调用摄像头
    // this.getCamera();
    //
    // if (this.showFace) {
    this.OpenCamera();
    // }
  },
  destroyed() {
    if (this.faceInterval) {
      clearInterval(this.faceInterval);
    }
    this.CloseCamera();
  },
  methods: {
    openFaceRecognition() {
      this.isFace = true;
      this.OpenCamera();
    },
    handleClose() {
      this.CloseCamera();
      this.$emit("closepop"); //通知父组件改变。
    },
    // 调用打开摄像头功能
    getCamera() {
      // 获取 canvas 画布
      this.canvas = document.getElementById("canvasCamera");
      this.context = this.canvas.getContext("2d");
      // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      navigator.mediaDevices
        .getUserMedia({
          video: true,
        })
        .then((stream) => {
          // 摄像头开启成功
          this.mediaStreamTrack =
            typeof stream.stop === "function" ? stream : stream.getTracks()[0];
          this.video_stream = stream;
          this.$refs.video.srcObject = stream;
          this.$refs.video.play();

          this.faceInterval = setInterval(() => {
            this.setImage();
          }, this.detail.openCameraInvigilateInterval * 60000);
        })
        .catch((err) => {
          console.log(err);
          console.error("摄像头开启失败，请检查摄像头是否可用！");
          this.$message.error("摄像头开启失败，请检查摄像头是否可用！");
        });
    },
    // 拍照 绘制图片
    setImage() {
      console.log("拍照");
      // 点击canvas画图
      // 获取图片base64链接如果不是真的喜欢如果不是真的喜欢
      this.context.drawImage(this.$refs.video, 0, 0, 480, 320);
      const image = this.canvas.toDataURL("image/png");
      this.imgSrc = image;
      if (this.imgSrc) {
        this.checkFace(this.imgSrc);
      } else {
        let haveImg = setInterval(() => {
          if (this.imgSrc) {
            this.checkFace(this.imgSrc);
            clearInterval(haveImg);
          }
        }, 300000);
      }
      this.$emit("refreshDataList", this.imgSrc);
    },
    checkFace(imgSrc) {
      let params = {};
      params.image = imgSrc;
      params.batchId = this.detail.examUid;
      params.relationId = this.detail.examGradeUid;
      params.sourceType = "examing";
      params.callConfigList = [0, 3];
      Api.verifyFaceAll(params)
        .then((res) => {
          if (res.data.status === 1) {
            if (res.data.businessData.isSuccess) {
              console.warn(res.data);
            } else {
              console.warn(res.data);
            }
          } else {
            this.$message.error(res.data.errorMessage);
          }
          console.warn(res);
        })
        .catch((err) => {
          console.warn(err);
        });
    },
    // 打开摄像头
    OpenCamera() {
      console.log("打开摄像头");
      this.getCamera();
    },
    // 关闭摄像头
    CloseCamera() {
      console.log("关闭摄像头");
      this.MediaStreamTrack && this.MediaStreamTrack.stop();
      this.$refs.video.srcObject.getTracks()[0].stop();
    },
  },
};
</script>

<style lang="scss" scoped>
video {
  // width: 387px;
  // width: 200px;
  height: 150px;
  border: 2px solid #ffffff;
  border-radius: 8px;
  // height: 300px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
}
.exam-face {
  width: 386px;
  // height: 210px;
  background: linear-gradient(
    136deg,
    rgba(255, 255, 255, 0.82),
    rgba(255, 255, 255, 0.8) 100%
  );
  border: 1.5px dashed #ffffff;
  border-radius: 15px;
  box-shadow: 0px 2px 5.5px 0px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(8.4px);
  &.close {
    height: 52px;
    overflow: hidden;
    .icon {
      transform: rotate(180deg);
    }
  }
  .face-title {
    width: 100%;
    height: 52px;
    background: #102345;
    border-radius: 15px 15px 0px 0px;

    display: flex;
    padding: 0 24px;
    align-items: center;
    justify-content: space-between;
    .title-text {
      font-size: 20px;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
      display: inline-block;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        border: 3px solid #fa6a67;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        right: -25px;
        top: 50%;
        margin-top: -6px;
      }
    }
  }
  .face-main {
    width: 100%;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>