/*
 * @Author: your name
 * @Date: 2021-09-22 10:02:54
 * @LastEditTime: 2022-01-21 18:22:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web-admin\src\api\settings.js
 */

import { axios } from './../plugin/axios'
export default {
    getUnreadMessageNum(params) {
        return axios.get(`/api/base/notify/unread-msg-num`, params)
    },
    getMessageList(params) {
        return axios.post(`/api/base/notify/list`, params)
    },
    readMessage (params) {
        return axios.post(`/api/base/notify/read-state`,params)
        
    }
}