<!--
 * @Author: your name
 * @Date: 2021-10-26 15:02:33
 * @LastEditTime: 2022-04-12 18:58:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web-exam\src\views\list\index.vue
-->
<template>
  <div class="exam-list">
    <Nav :newAvatar="newAvatar"></Nav>
    <div class="list-header">
      <div class="list-banner">
        <div class="exam-search">
          <div class="search-header">技能鉴定考试系统</div>
          <div class="search-desc">
            <!-- 多类型试题 × 智能化系统阅卷 × 多场景考试模式 -->
          </div>
          <div class="search-input-modal">
            <el-input
              placeholder="输入关键词进行搜索"
              type="text"
              v-model="listQuery.examUserListSearchBase.examName"
              class="search-input"
            />
            <el-button type="primary" @click="searchList" class="search-btn">
              <div class="btn-text">
                <img src="../../assets/list/search-btn.png" alt="" />
                <span>搜索</span>
              </div>
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="list-main">
        <FaceRecognition
          v-if="showFace"
          :showFace="showFace"
          :goExam="goExam"
          :detail="examDetail"
          @closepop="closeModal"
        ></FaceRecognition>
        <div class="main-header">
          <div style="display: flex">
            <div
              class="content-btn"
              :class="[activeType == 'make' && 'select']"
              @click="makeExam('make', 'make')"
            >
              我的考试
            </div>
            <div
              class="content-btn"
              :class="[activeType == 'makeUp' && 'select']"
              @click="makeExam('makeUp', 'makeUp')"
            >
              考试补考
            </div>
            <div
              class="content-btn"
              :class="[activeType == 'noSubmit' && 'select']"
              @click="getNoSubmitList('select')"
            >
              补交答卷
            </div>
          </div>
          <div class="file-btn" @click="goRecord">
            <span style="margin-right: 8px"> 考试档案 </span>
            <img src="../../assets/list/arrows-right.png" alt="" />
          </div>
        </div>
        <div class="main-content">
          <el-table
            v-if="tableData.length != 0"
            empty-text="暂无数据"
            class="customer-table"
            :data="tableData"
            :header-cell-style="tableHeaderColor"
            @click="openFaceModal(scope.row)"
            stripe
            style="width: 100%"
          >
            <el-table-column
              header-align="center"
              label="考试名称"
              style="font-size: 16px:color:#999999"
            >
              <template slot-scope="scope">
                <span style="padding-left: 32px" class="exam-name">{{
                  scope.row.examName
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="考试时间"
              width="380"
              style="font-size: 16px:color:#999999"
            >
              <template slot-scope="scope">
                <span
                  >{{ scope.row.beginTimeFormat }} 至
                  {{ scope.row.endTimeFormat }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="examTime"
              label="考试时长"
              width="180"
              style="font-size: 16px:color:#999999"
            >
              <template slot-scope="scope">
                <span>{{
                  scope.row.examTime ? `${scope.row.examTime / 60}分钟` : "不限"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="activeType != 'noSubmit'"
              align="center"
              prop="examTime"
              label="参加次数"
              width="180"
            >
              <template slot-scope="scope">
                <span
                  ><span
                    style="
                      font-size: 14px;
                      font-weight: 400;
                      text-align: left;
                      color: #1fa3ff;
                      line-height: 15px;
                    "
                    >{{ scope.row.attendTimes }}</span
                  >/{{
                    scope.row.maxExamNum ? scope.row.maxExamNum : "无限"
                  }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              v-if="activeType == 'noSubmit'"
              align="center"
              prop="examTime"
              label="状态"
              width="180"
            >
              <template slot-scope="scope">
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    text-align: left;
                    color: #1fa3ff;
                    line-height: 15px;
                  "
                >
                  {{ scope.row.gradeStatusCodeName }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="address"
              label="操作"
              width="180"
            >
              <template slot-scope="scope">
                <div
                  v-if="activeType == 'noSubmit'"
                  class="handle-btn"
                  @click="goSubmit(scope.row)"
                >
                  补交
                </div>
                <div
                  v-else
                  class="handle-btn"
                  @click="openFaceModal(scope.row)"
                >
                  立即参加
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div v-else class="empty">
            <img src="../../assets/exam/empty.png" alt="" />
            暂无考试数据
          </div>
          <div
            v-if="tableData.length != 0"
            style="
              position: absolute;
              height: 30px;
              margin: 36px auto;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              bottom: 0;
            "
          >
            <el-pagination
              v-if="loading"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pageSize"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="mask" v-if="showMaskModal" @click="showMaskModal = false">
      <div class="tips-modal" @click.stop>
        <div class="modal-head">
          <img
            class="icon"
            src="../../assets/components/error-icon.png"
            alt=""
          />
          温馨提示
        </div>
        <div class="modal-main">
          <div class="main-head">
            系统未查询到您的相关信息 请选择以下任一方式进行身份认证
          </div>
          <div class="main-body">
            <photograph
              v-if="showPhotograph"
              :showFace="showPhotograph"
              @closepop="closeModal"
              @closeAll="closeAll"
            ></photograph>
            <el-upload
              v-else-if="imageUrl && !showPhotograph"
              class="avatar-uploader"
              action=""
              v-model="avatar"
              :show-file-list="false"
              :on-change="handleChange"
              :auto-upload="false"
            >
              <img :src="imageUrl" class="avatar" />
            </el-upload>
            <img
              v-else-if="userInfo.extraProperties.Sex == 'female'"
              src="../../assets/components/head-img-female.png"
              alt=""
            />
            <img v-else src="../../assets/components/head-img.png" alt="" />
          </div>
          <div class="main-foot" v-if="!showPhotograph">
            <div v-if="imageUrl" @click.stop="upload" class="btn">
              <img
                style="margin-right: 15px"
                src="../../assets/components/upload-icon.png"
                alt=""
              />
              <div>确认上传</div>
            </div>
            <el-upload
              v-else
              class="avatar-uploader"
              action=""
              v-model="avatar"
              :show-file-list="false"
              :on-change="handleChange"
              :auto-upload="false"
            >
              <!-- :before-upload="beforeAvatarUpload" -->
              <div class="btn">
                <img
                  style="margin-right: 15px"
                  src="../../assets/components/upload-icon.png"
                  alt=""
                />
                <div>选择照片</div>
              </div>
            </el-upload>
            <div
              v-if="!imageUrl"
              class="btn"
              style="margin-left: 50px"
              @click="showPhotographModal"
            >
              <img
                style="margin-right: 15px"
                src="../../assets/components/upload-icon.png"
                alt=""
              />
              <div>拍照上传</div>
            </div>
            <div
              v-else
              class="btn"
              style="margin-left: 50px; width: 80px"
              @click="reset"
            >
              返回
            </div>
            <!-- <el-button
              type="primary"              
              @click.stop="upload"
              >确认</el-button
            > -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Nav from "@/components/nav.vue";
import Api from "@/api/examList";
import userApi from "@/api/home.js";
import FaceRecognition from "@/components/faceRecognition";
import photograph from "@/components/photograph";
export default {
  components: {
    Nav,
    photograph,
    FaceRecognition,
  },
  data() {
    return {
      loading: false,
      avatar: "",
      imageUrl: "",
      formData: {},
      showMaskModal: false,
      examDetail: {},
      total: 0,
      page: 1,
      pageSize: 10,
      showFace: false,
      showPhotograph: false,
      newAvatar: "",
      tableData: [],
      listQuery: {
        SkipCount: 0,
        MaxResultCount: 10,
        examUserListSearchBase: {
          examClassCode: "exam",
          examName: "",
        },
      },
      activeType: "make",
      userInfo: {
        extraProperties: {},
      },
    };
  },
  methods: {
    reset() {
      this.imageUrl = "";
    },
    showPhotographModal() {
      this.showPhotograph = true;
    },
    handleChange(file, fileList) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      console.warn("isLt2M", isLt2M);
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      } else {
        console.warn(file.raw);
        let formData = new FormData();
        this.formData = formData;
        formData.append("file", file.raw);
        console.warn(this.formData);
        this.imageUrl = URL.createObjectURL(file.raw);
        console.warn("imageUrl", this.imageUrl);
        this.$forceUpdate();
      }
      return isLt2M;
    },
    closeAll() {
      this.showMaskModal = false;
      this.showPhotograph = false;
    },
    upload() {
      let fileRelationId = "";
      if (sessionStorage.getItem("userId")) {
        fileRelationId = sessionStorage.getItem("userId");
      }
      axios
        .post(
          `/api/app/file/upload-file-system?fileTypeCode=user&fileRelationId=${fileRelationId}`,
          this.formData
        )
        .then((res) => {
          this.avatar = res.data;
          // this.$message.success('上传成功');
          axios
            .post(
              `/api/base/user/faceimage/${fileRelationId}?blobName=${this.avatar.blobName}`
            )
            .then((res) => {
              if (res) {
                this.$message.success("照片上传成功");
                this.userInfo.extraProperties.FaceImage = this.avatar.blobName;
                sessionStorage.setItem(
                  "userInfo",
                  JSON.stringify(this.userInfo)
                );
                this.closeAll();
              } else {
                this.$message.success("照片上传失败，请重新上传或联系管理员");
              }
            })
            .catch((err) => {
              this.$message.error(err);
            });
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    // 修改table header的背景色
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "font-size:16px;color: #999999;font-weight: 500;";
      }
    },
    closeModal() {
      this.showFace = false;
      this.showPhotograph = false;
    },
    goRecord() {
      this.$router.push("/exam/record");
    },
    goSubmit(item) {
      let _this=this;
      if (sessionStorage.getItem("baseDetail")) {
        let base = JSON.parse(sessionStorage.getItem("baseDetail"));
        if (base.examGrade.id == item.examGradeUid) {
          let params = {
            examAnswers: [],
          };
          let detail = {};
          let unfinishedNum = 0;
          params.examUid = item.examUid;
          params.examGradeUid = item.examGradeUid;
          detail = base;
          detail.paperDetail.paperNodes.forEach((item) => {
            item.questions.forEach((subItem) => {
              if (subItem.fillAnswer) {
                unfinishedNum += 1;
              } else if (subItem.fillAnswer == false) {
                let obj = {
                  questionUid: subItem.id,
                  isSetBookMark: subItem.isSign ? true : false,
                };
                if (Array.isArray(subItem.answerText)) {
                  if (subItem.answerText.length == 0) {
                    obj.answerText = "";
                  } else if (subItem.answerText.length == 1) {
                    obj.answerText = subItem.answerText[0];
                  } else {
                    obj.answerText = subItem.answerText.join("|");
                  }
                } else {
                  obj.answerText = subItem.answerText;
                }
                if (
                  subItem.questionBaseTypeCode == "judge_correct" &&
                  subItem.answerText == "N"
                ) {
                  if (subItem.text) {
                    obj.answerText = subItem.text;
                  }
                }
                if (subItem.questionBaseTypeCode == "fill") {
                  let textArr = [];
                  if (subItem.answerText.length > 1) {
                    subItem.answerText.forEach((item) => {
                      textArr.push(item.text);
                    });
                    obj.answerText = textArr.join("|");
                  } else {
                    obj.answerText = subItem.answerText[0].text;
                  }
                }
                params.examAnswers.push(obj);
              }
              params.noAnswerQuestionNum = unfinishedNum;
            });
          });
          Api.goSubmit(item.examUid, item.examGradeUid, params)
            .then((res) => {
              console.warn(res);
              sessionStorage.setItem("baseDetail", "");
              this.$message.success("补交成功");
              setTimeout(function(){
                _this.getNoSubmitList();
              },1000)
            })
            .catch((err) => {
              console.warn(err.message);
              this.$message.warn(err.data.message);
            });
          return;
        }
      }
      if (localStorage.getItem("examList").length != 0) {
        let list = JSON.parse(localStorage.getItem("examList"));
        let arr = list;
        let params = {
          examAnswers: [],
        };
        let detail = {};
        let unfinishedNum = 0;
        params.examUid = item.examUid;
        params.examGradeUid = item.examGradeUid;
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].examGrade.id == item.examGradeUid) {
            detail = arr[i];
            detail.paperDetail.paperNodes.forEach((item) => {
              item.questions.forEach((subItem) => {
                if (subItem.fillAnswer) {
                  unfinishedNum += 1;
                } else if (subItem.fillAnswer == false) {
                  let obj = {
                    questionUid: subItem.id,
                    isSetBookMark: subItem.isSign ? true : false,
                  };
                  if (Array.isArray(subItem.answerText)) {
                    if (subItem.answerText.length == 0) {
                      obj.answerText = "";
                    } else if (subItem.answerText.length == 1) {
                      obj.answerText = subItem.answerText[0];
                    } else {
                      obj.answerText = subItem.answerText.join("|");
                    }
                  } else {
                    obj.answerText = subItem.answerText;
                  }
                  if (
                    subItem.questionBaseTypeCode == "judge_correct" &&
                    subItem.answerText == "N"
                  ) {
                    if (subItem.text) {
                      obj.answerText = subItem.text;
                    }
                  }
                  if (subItem.questionBaseTypeCode == "fill") {
                    let textArr = [];
                    if (subItem.answerText.length > 1) {
                      subItem.answerText.forEach((item) => {
                        textArr.push(item.text);
                      });
                      obj.answerText = textArr.join("|");
                    } else {
                      obj.answerText = subItem.answerText[0].text;
                    }
                  }
                  params.examAnswers.push(obj);
                }
                params.noAnswerQuestionNum = unfinishedNum;
              });
            });
          }
        }
        Api.goSubmit(item.examUid, item.examGradeUid, params)
          .then((res) => {
            //this.getNoSubmitList();
            console.warn(res);
            this.$message.success("补交成功");
            setTimeout(function(){
                _this.getNoSubmitList();
              },1000)
          })
          .catch((err) => {
            console.warn(err.message);
            this.$message.warn(err.data.message);
          });
      } else {
        let params = {
          examAnswers: [],
          examUid: item.examUid,
          examGradeUid: item.examGradeUid,
        };
        Api.goSubmit(item.examUid, item.examGradeUid, params)
          .then((res) => {
            //this.getNoSubmitList();
            console.warn(res);
            this.$message.success("补交成功");
            setTimeout(function(){
                _this.getNoSubmitList();
            },1000)
          })
          .catch((err) => {
            console.warn(err.message);
            this.$message.warn(err.data.message);
          });
      }
    },
    getNoSubmitList(val) {
      if (val) {
        this.page = 1;
        this.loading = false;
        // this.pageSize = 1;
      }
      this.activeType = "noSubmit";
      this.listQuery.examUserListSearchBase.isExamination = null;
      if (this.pageSize) {
        this.listQuery.MaxResultCount = this.pageSize;
      }
      this.listLoading = true;
      this.listQuery.SkipCount =
        (this.page - 1) * this.listQuery.MaxResultCount;
      this.listQuery.SkipCount=this.listQuery.SkipCount||0;
      Api.getNoSubmitList(this.listQuery)
        .then((res) => {
          this.tableData = res.data.items;
          this.total = res.data.totalCount;
          this.tableData.forEach((item) => {
            if (item.beginTimeFormat) {
              item.beginTimeFormat =
                item.beginTimeFormat.split(" ")[0] +
                " " +
                item.beginTimeFormat.split(" ")[1].split(":")[0] +
                ":" +
                item.beginTimeFormat.split(" ")[1].split(":")[1];
            }
            if (item.endTimeFormat) {
              item.endTimeFormat =
                item.endTimeFormat.split(" ")[0] +
                " " +
                item.endTimeFormat.split(" ")[1].split(":")[0] +
                ":" +
                item.endTimeFormat.split(" ")[1].split(":")[1];
            }
          });
          this.loading = true;
        })
        .catch((err) => {
          console.warn(err);
        });
    },
    searchList() {
      this.listQuery.SkipCount = 0;
      this.listQuery.MaxResultCount = 10;
      switch (this.activeType) {
        case "make":
          this.makeExam("make", "make");
          break;
        case "makeUp":
          this.makeExam("makeUp", "makeUp");
          break;
        case "noSubmit":
          this.getNoSubmitList();
          break;
      }
    },
    makeExam(type, val) {
      this.activeType = type;
      if (type == "makeUp") {
        this.listQuery.examUserListSearchBase.isExamination = true;
      } else {
        this.listQuery.examUserListSearchBase.isExamination = false;
      }
      if (val == "make" || val == "makeUp") {
        this.loading = false;
        this.page = 1;
      } else {
        this.page = val;
      }
      this.requestList();
      this.$forceUpdate();
    },
    handleCurrentChange(val) {
      this.page = val;
      console.warn("this.activeType handleCurrentChange", this.activeType);
      switch (this.activeType) {
        case "make":
          this.makeExam("make", val);
          break;
        case "makeUp":
          this.makeExam("makeUp", val);
          break;
        case "noSubmit":
          this.getNoSubmitList();
          break;
      }
      // this.requestList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.listQuery.SkipCount = 0;
      this.listQuery.MaxResultCount = 10;
      console.warn("this.activeType handleSizeChange", this.activeType);
      switch (this.activeType) {
        case "make":
          this.makeExam("make");
          break;
        case "makeUp":
          this.makeExam("makeUp");
          break;
        case "noSubmit":
          this.getNoSubmitList();
          break;
      }
      // this.requestList();
    },
    openMaskModal() {
      this.showMaskModal = true;
    },
    openFaceModal(item) {
      console.warn(item);
      if (!item.isOpenFaceAuthentication) {
        this.examDetail = item;
        this.showFace = true;
      } else {
        if (sessionStorage.getItem("userInfo")) {
          this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        }
        if (this.userInfo.extraProperties.FaceImage) {
          console.warn("有头像");
          this.examDetail = item;
          this.showFace = true;
          this.$forceUpdate();
        } else {
          this.openMaskModal();
        }
      }
    },
    goExam(item) {
      console.warn(item);
      let params = {};
      if (item.maxExamNum) {
        if (item.attendTimes >= item.maxExamNum) {
          this.$message.error("参加次数已超过限制,请联系管理员");
          return;
        }
      }
      if (item.isOpenFaceAuthentication) {
        params.faceVerificationKey = item.verificationKey;
        params.examGradeUid = item.examGradeUid;
      }
      if (sessionStorage.getItem("baseDetail")) {
        sessionStorage.removeItem("baseDetail");
      }
      if (item.examDoModeCode == "paper") {
        Api.checkExam(item.examUid, item.examArrangeUid, params)
          .then((res) => {
            let routeData;
            if (item.isOpenFaceAuthentication) {
              routeData = this.$router.resolve({
                path: "/exam/paper",
                query: {
                  examUid: item.examUid,
                  examArrangeUid: item.examArrangeUid,
                  faceVerificationKey: item.verificationKey,
                  examGradeUid: item.examGradeUid,
                },
              });
            } else {
              routeData = this.$router.resolve({
                path: "/exam/paper",
                query: {
                  examUid: item.examUid,
                  examArrangeUid: item.examArrangeUid,
                },
              });
            }
            this.jscomNewOpenMaxWindow(routeData.href, "_blank");
            this.requestList();
          })
          .catch((err) => {
            console.warn(err);
          });
      } else if (item.examDoModeCode == "question") {
        Api.checkExam(item.examUid, item.examArrangeUid, params)
          .then((res) => {
            console.warn(res);
            let routeData;
            if (item.isOpenFaceAuthentication) {
              routeData = this.$router.resolve({
                path: "/exam/question",
                query: {
                  examUid: item.examUid,
                  examArrangeUid: item.examArrangeUid,
                  faceVerificationKey: item.verificationKey,
                  examGradeUid: item.examGradeUid,
                },
              });
            } else {
              routeData = this.$router.resolve({
                path: "/exam/question",
                query: {
                  examUid: item.examUid,
                  examArrangeUid: item.examArrangeUid,
                },
              });
            }
            // let routeData = this.$router.resolve({ path: '/exam/question', query: { examUid: item.examUid, examArrangeUid: item.examArrangeUid } });
            this.jscomNewOpenMaxWindow(routeData.href, "_blank");
            this.requestList();
          })
          .catch((err) => {
            console.warn(err);
          });
      }
      this.closeModal();
    },
    requestList() {
      if (this.pageSize) {
        this.listQuery.MaxResultCount = this.pageSize;
      }
      this.listLoading = true;
      this.listQuery.SkipCount =
        (this.page - 1) * this.listQuery.MaxResultCount;
      this.listQuery.SkipCount=this.listQuery.SkipCount||0;
      Api.getList(this.listQuery)
        .then((res) => {
          this.tableData = res.data.items;
          this.total = res.data.totalCount;
          this.tableData.forEach((item) => {
            if (item.beginTimeFormat) {
              item.beginTimeFormat =
                item.beginTimeFormat.split(" ")[0] +
                " " +
                item.beginTimeFormat.split(" ")[1].split(":")[0] +
                ":" +
                item.beginTimeFormat.split(" ")[1].split(":")[1];
            }
            if (item.endTimeFormat) {
              item.endTimeFormat =
                item.endTimeFormat.split(" ")[0] +
                " " +
                item.endTimeFormat.split(" ")[1].split(":")[0] +
                ":" +
                item.endTimeFormat.split(" ")[1].split(":")[1];
            }
          });
          this.loading = true;
        })
        .catch((err) => {
          console.warn(err);
        });
    },
    jscomNewOpenMaxWindow(url, target) {
      let tt, w, left, top, width, height;
      width = screen.width;
      height = screen.height - 60;
      left = 0;
      if (left < 0) {
        left = 0;
      }

      top = 0;
      if (top < 0) {
        top = 0;
      }

      tt =
        "toolbar=no, menubar=no, scrollbars=yes,resizable=no,location=no, status=yes,fullscreen=yes,";
      tt =
        tt +
        "width=" +
        width +
        ",height=" +
        height +
        ",left=" +
        left +
        ",top=" +
        top;
      w = window.open(url, target, tt);
      try {
        w.focus();
        return w;
      } catch (e) {}
    },
  },
  destroyed() {
    localStorage.removeItem("isTest");
  },
  created() {
    //如果有当前localStorage isTest 则 清空query
    if (localStorage.getItem("isTest")) {
      this.$router.push({ query: {} });
    }

    //
    if (this.$route && this.$route.query && this.$route.query.name) {
      //定义一个 localStorage isTest
      this.listQuery.examUserListSearchBase.examName = this.$route.query.name;

      localStorage.setItem("isTest", true);
      // 这里处理携带进来的参数
    }

    this.requestList();
    if (sessionStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    } else {
      if (sessionStorage.getItem("userId")) {
        userApi
          .getUserInfo(sessionStorage.getItem("userId"))
          .then((result) => {
            sessionStorage.setItem("userInfo", JSON.stringify(result.data));
            this.userInfo = result.data;
            if (!localStorage.getItem("examList")) {
              localStorage.setItem("examList", []);
            }
          })
          .catch((err) => {
            console.warn(err);
          });
      }
    }
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        if (newVal.query.name) {
          this.listQuery.examUserListSearchBase.examName = newVal.query.name;
        } else {
          this.listQuery.examUserListSearchBase.examName = "";
        }
        this.searchList();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  height: 100%;
  border: none;
  padding-left: 32px;
}
::v-deep .el-input--mini .el-input__inner {
  height: 100%;
  // border: none;
  // padding-left: 32px;
}
::v-deep .el-pagination__editor.el-input .el-input__inner {
  height: 100%;
  text-align: center;
}
::v-deep .el-pagination__sizes {
  border: 1px solid #e2e3e4;
  border-radius: 5px;
}
::v-deep .el-pagination__editor.el-input {
  border: 1px solid #e2e3e4;
  border-radius: 5px;
}
.el-menu {
  background-color: initial;
}

.exam-list {
  // height: calc(100% - 100px);
  min-height: 100%;
  position: relative;
  .list-header {
    height: 490px;
    background: url("../../assets/list/BG.png");

    .list-banner {
      height: 400px;
      position: relative;
      .exam-search {
        width: 1300px;
        margin: 0 auto;
        position: absolute;
        top: 70%;
        transform: translate(-50%, -50%);
        left: 50%;
        .search-header {
          font-size: 56px;
          font-weight: 400;
          text-align: left;
          color: #1e2046;
          line-height: 64px;
        }
        .search-desc {
          font-size: 16px;
          font-weight: 300;
          text-align: left;
          color: #1e2046;
          line-height: 66px;
          // margin-bottom: 30px;
          margin-bottom: 96px;
        }
        .search-input-modal {
          display: flex;
          width: 650px;
          height: 70px;
          position: relative;
          background: #ffffff;
          border-radius: 15px;
          overflow: hidden;
          box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);
          .search-btn {
            width: 170px;
            height: 100%;
            z-index: 99;
            position: absolute;
            right: 0;
            .btn-text {
              display: flex;
              align-items: center;
              justify-content: center;
              span {
                margin-left: 16px;
                height: 28px;
                font-size: 20px;
                font-weight: 500;
                text-align: left;
                color: #ffffff;
                line-height: 28px;
              }
            }
          }
        }
      }
    }
  }
  .main {
    background: url("../../assets/exam/exam-bg2.png");
    background-size: cover;
    background-repeat: repeat;
    width: 100%;
    height: 100%;
    padding-bottom: 50px;

    .list-main {
      // padding-top: 150px;

      padding-top: 40px;
      width: 1300px;
      margin: 0 auto 20px;
      .exam-name {
        font-size: 14px;
        font-weight: 600;
        text-align: left;
        color: #000000;
        line-height: 15px;
      }
      .handle-btn {
        cursor: pointer;
        width: 84px;
        height: 30px;
        border: 1px solid #979797;
        border-radius: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        &:hover {
          background: #1fa3ff;
          color: #fff;
          border: none;
        }
      }
      .main-header {
        display: flex;
        margin-bottom: 18px;
        justify-content: space-between;
        align-items: center;
        .content-btn {
          cursor: pointer;
          width: 132px;
          height: 46px;
          border-radius: 25px;
          line-height: 46px;
          text-align: center;
          font-size: 19px;
          font-weight: 700;
          background: #f5f5f7;
          color: #2d2d2d;
          margin-right: 35px;
          &.select {
            background: rgba(31, 163, 255, 0.09);
            color: #1fa3ff;
          }
        }
        .file-btn {
          width: 126px;
          height: 42px;
          cursor: pointer;
          background-color: #fdfefe;
          border: 1px solid #cacaca;
          font-size: 16px;
          font-weight: 600;
          color: #000000;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .main-content {
        // min-height: 680px;
        .empty {
          width: 100%;
          min-height: 350px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: 400;
          text-align: center;
          color: #2c2c2c;
        }
        min-height: 350px;
        padding-bottom: 102px;
        position: relative;
        height: auto;
        background: linear-gradient(
          136deg,
          rgba(255, 255, 255, 0.82),
          rgba(255, 255, 255, 0.8) 100%
        );
        border-radius: 15px;
        box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.04);
        overflow: hidden;
      }

      .main-copyright {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        color: #a5a5a5;
        line-height: 22px;
      }
    }
  }
  .mask {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    // display: none;
    z-index: 20;
    background: rgba(0, 0, 0, 0.5);
    // filter: alpha(opacity=60);
    // opacity: 0.5 !important;
    .tips-modal {
      overflow: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -285px;
      margin-left: -240px;
      width: 480px;
      height: 570px;
      background: #ffffff;
      border-radius: 18px;
      z-index: 50 !important;
      background-color: White;

      .modal-head {
        box-sizing: border-box;
        height: 90px;
        text-align: center;
        padding: 30px 0;
        font-size: 24px;
        font-weight: 700;
        color: #333333;
        border-bottom: 1px solid #e6e6e6;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          margin-right: 10px;
        }
      }
      .modal-main {
        .main-head {
          width: 240px;
          height: 53px;
          font-size: 16px;
          font-weight: 400;
          text-align: center;
          color: #999999;
          line-height: 32px;
          margin: 20px auto 30px;
        }
        .main-body {
          display: flex;
          justify-content: center;
          margin-bottom: 30px;
        }
        .main-foot {
          width: 100%;
          display: flex;
          justify-content: center;
          .btn {
            width: 145px;
            height: 57px;
            background: #f8f8f8;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
  .avatar {
    width: 258px;
    height: 258px;
  }
}
</style>
<style>
/* .main-content .el-table th.is-leaf, */
.main-content .el-table td,
.main-content .el-table--border,
.main-content .el-table__body-wrapper {
  /* grey */
  border: none;
}
.main-content .el-table::before {
  height: 0;
}
</style>