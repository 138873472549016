<!--
 * @Author: your name
 * @Date: 2022-01-06 17:28:23
 * @LastEditTime: 2022-03-11 17:37:25
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \web-pc\src\components\wangEditor\wangEditor.vue
-->
<template>
    <div class="smart-editor"> 
        <div :id="id">
            <p></p>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import E from 'wangeditor';
export default {
    props: {
        id: {
            required: true,
            type: String,
            default: '1',
        },
        value: {
            type: String,
            default: '',
        },
        meanArray: {
            // 自定义菜单
            type: Array,
            default: null,
        },
        autofocus: {
            type: Boolean,
            default: false
        }
    },
    model: {
        prop: 'value',
        event: 'change',
    },
    watch: {
        value (value) {
            if (value !== this.editor.txt.html()) {
                this.editor.txt.html(this.value);
            }
        },
        //value为编辑框输入的内容，这里我监听了一下值，当父组件调用得时候，如果给value赋值了，子组件将会显示父组件赋给的值
    },
    data() {
        return {
            // 默认有这么多菜单，meanArray有值以meanArray为准
            defaultMenus: ['bold', 'image'],
            editor: null
        };
    },
    methods: {
        init() {
            if (this.editor) {
                return
            }
            
            this.editor = new E('#' + this.id);
            this.editor.config.showFullScreen = false
            // this.editor.config.uploadImgShowBase64 = true; // 使用 base64 保存图片
            this.editor.config.focus = this.autofocus
            this.editor.config.customUploadImg = function (resultFiles, insertImgFn) {
                // resultFiles 是 input 中选中的文件列表
                // insertImgFn 是获取图片 url 后，插入到编辑器的方法
                //  this.$axios
                let formData = new FormData();

                formData.append("file", resultFiles[0]);
                console.warn(formData);
                axios.post(`/api/app/file/upload-file-system`, formData).then(res => {
                console.log(res);
                let host = window.location.protocol + "//" + window.location.host;
                // 上传图片，返回结果，将图片插入到编辑器中
                insertImgFn(res.config.baseURL + res.data.fileServerUrl)

                // this.$emit('insertImgFn',  res.data.fileServerUrl)
                })

                console.warn(resultFiles[0], insertImgFn);

            }
            this.setMenus(); //设置菜单
            this.editor.config.onchange = html => {
                this.$emit('change', html); // 将内容同步到父组件中
            };                 
            this.editor.config.onfocus = () => {
                const $view = this.editor.$toolbarElem.elems[0] 
                if (!$view) {
                    return
                }
                const $btn = $view.children[0]
                
                $btn.click()
                this.$nextTick(() => {
                    $btn.click()
                })
            };            
            this.editor.create(); //创建编辑器
        },
        $_initValue () {
            if (this.value !== this.editor.txt.html()) {
                this.editor.txt.html(this.value);
                this.$forceUpdate()
            }
        },
        setMenus() {
            // 设置菜单
            if (this.meanArray) {
                this.editor.config.menus = this.meanArray;
            } else {
                this.editor.config.menus = this.defaultMenus;
            }
        },
        getHtml() {
            // 得到文本内容
            return this.editor.txt.html();
        },
        setHtml(txt) {
            // 设置富文本里面的值
            this.editor.txt.html(txt);
        },
    },
    mounted () {
        this.init();
        this.$_initValue(); 
    },
    beforeDestroy() {
        // 销毁编辑器
        this.editor.destroy()
        this.editor = null
    }
};
</script>
