/*
 * @Author: your name
 * @Date: 2021-09-22 10:02:54
 * @LastEditTime: 2022-01-13 11:40:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web-admin\src\api\settings.js
 */

import { axios } from './../plugin/axios'
export default {
    getDetail(examUid, examArrangeUid, params) {
        return axios.post(`/api/examservice/exam/${examUid}/arranges/${examArrangeUid}/join`, params)
    },
    getPaperAnaswer(examUid, examGradeUid, params) {
        return axios.get(`/api/examservice/exam/${examUid}/judges/${examGradeUid}/view-detail`, params)
    },
    savePaper(examUid, examGradeUid, params) {
        return axios.post(`/api/examservice/exam/${examUid}/grades/${examGradeUid}/save-user-answer`, params)
    },
    submitPaper(examUid, examGradeUid, params) {
        return axios.post(`/api/examservice/exam/${examUid}/grades/${examGradeUid}/save-user-answer-submit`, params)
    },
    operationList(params) {
        return axios.post(`/api/examservice/exam/monitor/operation/list `, params)
    },
    pauseExam(gradeUid) {
        return axios.get(`/api/examservice/exam/grades/${gradeUid}/pause-exam`)
    },
    resumeExam(gradeUid) {
        return axios.get(`/api/examservice/exam/grades/${gradeUid}/resume-exam`)
    },
    updateStatus(params) {
        return axios.post(`/api/examservice/exam/monitor/operation/update-status?statusCode=success`, params)
    },
    screen(params) {
        return axios.post(`/api/examservice/exam/monitor/cut-screen`, params)
    },
    getPaperKnowledgeStatisticMe(params){
        return axios.post(`/api/examservice/exam/statistic/question/group/knowledge/list/me`,params)
    },
    getScoringAnalysisInfo(examUid,examGradeUid){
        return axios.get(`/api/examservice/exam/statistic/question/group/knowledge/${examUid}/grades/${examGradeUid}`);
    }

}