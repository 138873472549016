<!--
 * @Author: your name
 * @Date: 2021-07-09 14:22:41
 * @LastEditTime: 2022-04-12 16:54:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \vindel-map-pc\src\App.vue
-->
<template>
    <!-- <div class="exam" @click="enterScreen"> -->
    <div class="exam" @click="enterFullScreen">
       <el-dialog
          title="考试暂停"
          :visible.sync="pauseDialogVisible"
         :show-close="false"
        :modal-append-to-body='false'
        :close-on-click-modal='false'
          width="30%"
          :before-close="handleClose">
            <div style="margin-bottom:16px;" v-if="isPauseText">消息通知:  {{isPauseText}}</div>         
          <span>考试暂停中</span>
          <span slot="footer" class="dialog-footer" v-if="!isPause">
            <el-button type="primary" @click="continueExam">继续考试</el-button>
          </span>
        </el-dialog>
         <el-dialog
           
          title="保存试卷"
          :visible.sync="saveDialogVisible"
         :show-close="false"
        :modal-append-to-body='false'
        :close-on-click-modal='false'
          width="30%"
          :before-close="handleClose">
          <span v-if="unfinishedNum">您还有{{unfinishedNum}}题未完成,确定保存吗?</span>
            <div style="width: 100%;display: flex;justify-content: flex-end;margin-top: 8px;">
                       <el-button  @click.stop="saveDialogVisible = false">取消</el-button>

                <el-button type="primary" @click.stop="showSaveModal">确定</el-button></div>
        </el-dialog>
          <el-dialog
            title="提示"
            :visible.sync="noOperation"
            :show-close="false"
            :modal-append-to-body='false'
            :close-on-click-modal='false'
            width="30%"
            :before-close="handleClose">
            <span>
                {{`当前已经${count}秒内无操作，${cutdownNum}秒内无操作将强制交卷，如需继续作答，请任意操作鼠标键盘...`}}
            </span>
            <!-- <div style="width: 100%;display: flex;justify-content: flex-end;margin-top: 8px;">
                <el-button type="primary" @click.stop="noOperation = false">确定</el-button>
            </div> -->
        </el-dialog>
       <el-dialog
       
          title="提交试卷"
          :visible.sync="submitDialogVisible"
         :show-close="false"
        :modal-append-to-body='false'
        :close-on-click-modal='false'
          width="30%"
          :before-close="handleClose">
                <span v-if="unfinishedNum">您还有{{unfinishedNum}}题未完成,确定交卷吗?</span>
 <div style="width: 100%;display: flex;justify-content: flex-end;margin-top: 8px;">         
      
       <el-button   @click.stop="submitDialogVisible = false">取消</el-button>
       <el-button type="primary"  @click.stop="showSubmitModal">确定</el-button>
                </div>
        </el-dialog>
        <el-dialog
          title="试题检查"
          :visible.sync="dialogVisible"
          width="30%"
          :before-close="handleClose">
          <span >没做的试题有:{{unfinishedNum}}个,已在试题导航中显示,并且已经有{{isSignNum}}个试题已做标注.</span>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click.stop="dialogVisible = false">关闭</el-button>
          </span>
        </el-dialog>
        <el-dialog
             :show-close="false"
             :modal-append-to-body='false'
             :close-on-click-modal='false'
             custom-class="submit-modal"
               :visible.sync="answerDialogVisible"
               width="30%"
               :before-close="closePage">
               <img class="icon-left" src="../../assets/exam/notification.png" alt="">
               <div>
                    <div class="title">答案提交成功</div>
              <div class="tips" v-if="canSeeGrade">本次考试客观题成绩为</div>
              <div class="score" v-if="canSeeGrade">{{gradeScore}}
                   <span style="font-size: 35px;font-family: PingFangSC, PingFangSC-Regular;font-weight: 400;text-align: left;color: #ff6c75;line-height: 41px;">分</span> </div>
              <!-- <div class="title" v-if="isDisplayResult">
                  <span v-if="isPass">恭喜您,过本次考试!</span>
                  <span v-else>您没通过本次考试</span>
              </div> -->
              <div style="display:flex;width:75%;margin:auto;">
                  <div class="submit-btn" style="width: 180px;height: 45px;" v-if="isAllowViewPaperNow"  @click.stop="goAnswerPaper">查看答卷</div>
                  <div class="submit-btn"  style="width: 180px;height: 45px;color: #FFF;background-color: #909399;border-color: #909399;"  @click.stop="closePaper">关闭试卷</div>              
                </div>               
               </div>         
        </el-dialog>
        <el-dialog
            :show-close="true"
            :modal-append-to-body='false'
            :close-on-click-modal='false'
            custom-class="submit-modal"
            :visible.sync="submitFailDialogVisible"
            width="30%"
            >
            <img class="icon-left" src="../../assets/exam/notification.png" alt="">
            <img class="icon-right" src="../../assets/exam/Ball.png" alt="">
            <div>
                <div class="title">答案提交失败，请重新提交</div>
                <div class="tips">网络可能存在问题,请再次提交!</div>
                <div style="display:flex;width:75%;margin:auto;margin-top:32px">
                  <div class="submit-btn" style="width: 180px;height: 45px;"  @click.stop="closeSubmitFailDialogVisible">关闭提示</div>
                </div>     
            </div>         
        </el-dialog> 
       <div class="exam-header">            
            <div class="header-detail">
                <img src="../../assets/exam/Breadcrumb.png" alt="">
                <div class="paper-headr">
                    <div class="exam-center">考试中心</div>
                    <div class="paper-name">{{ detail.paperDetail.paperName }} {{ !detail.exam.examDoModeCode?'':detail.exam.examDoModeCode == 'paper' ? '整卷模式' : '逐题模式' }}{{ `[${(detail.exam.examName||'')}]` }}</div>
                    <div class="paper-message"> 
                        <span style="margin-right:18px;"><span class="label">共:</span> {{ `${detail.paperDetail.examPaper.questionNum}题` }} </span>
                        <span style="margin-right:18px;"><span class="label">满分:</span> {{ `${detail.paperDetail.examPaper.totalScore}分` }} </span>
                        <span ><span class="label">总时长:</span> <span >{{examTime ? `${examTime}分钟`: '不限时'}}</span></span>
                    </div>
                </div>
            </div>
            <div class="headers-nav">
                <div class="user-detail">
                    <img v-if="imgUrl" :src="imgUrl" alt="">
                    <img
                      v-else-if="detail.userInfo.sex == 'female'"
                      class="userInfo-img"
                      src="../../assets/female.png"
                      alt=""
                    />
                    <img
                      v-else
                      class="userInfo-img"
                      src="../../assets/male.png"
                      alt=""
                    />
                    <div class="user-right">
                        <div class="user-name" v-if="detail.userInfo">{{detail.userInfo.userName}}({{detail.userInfo.name}})</div>
                        <div class="user-code" v-if="detail.userInfo">编号:{{detail.userInfo.userCode}}</div>
                    </div>
                </div>
                <div class="nav-right">
                     <span v-if="isSave" class="showSave" :class="hidden&&'hidden'">保存成功</span>
                       <div class="font-select">
                        <div class="select-text">
                            文字大小调整
                        </div>
                    <div>
                        <el-slider
                        style="width:120px;"
                          v-model="fontValue"
                          :format-tooltip="formatTooltip"
                          :step="1"
                          :max="3"
                          @change="fontChange"
                          show-stops>
                        </el-slider>
                        </div>
                    </div>
                    <div class="operate-btn">
                        <div class="exam-btn"  @click.stop="save('save')" style="margin-right:28px;">
                            <img src="../../assets/exam/btn-save.png" alt="">
                            <span>保存</span>
                        </div>
                        <div class="exam-btn"   @click.stop="checkQuestion" style="margin-right:28px;">
                            <img src="../../assets/exam/btn-check.png" alt="">
                            <span>检查</span>
                        </div>
                        <!-- <div class="exam-btn"   @click.stop="pause"  style="margin-right:28px;" v-if="detail.exam.isOpenBook">
                            <img src="../../assets/exam/btn-pause.png" alt="">
                            <span>暂停</span>
                        </div> -->
                        <div class="exam-btn"   @click.stop="save('submit')" v-if="!isStop">
                            <img src="../../assets/exam/btn-submit.png" alt="">
                            <span>交卷</span>
                        </div>
                        <!-- <el-button @click="save('save')">保存</el-button>
                        <el-button @click="checkQuestion">检查</el-button>
                        <el-button>暂停</el-button>
                        <el-button @click="save('submit')">交卷</el-button> -->
                    </div>
                </div>
            </div>
        </div>    
       
        <div class="exam-main">
            <el-row style="height: 100%">
                <el-col :span="16">
                    <div class="main-left" :class="sizeClass">
                            <div class="sign-tips">
                                <span>点击</span>
                                <img style="margin:0 8px;width:18px;height:18px;vertical-align: middle" src="./../../assets/exam/isSign.png" alt="">
                                <span>或双击题目可对试题进行标记</span>
                            </div>
                            <div class="paper-detail" v-for="(item, index) in detail.paperDetail.paperNodes" v-show="nodes_index == index" :key="index">
                                <div class="node-head">{{ sortList[index] }}、{{ item.examPaperNode.paperNodeName }} 【{{ item.examPaperNode.questionNum }}题,共{{ item.examPaperNode.totalScore }}分】</div>
                                <div class="node-content">
                                    <div class="node-question" :id="subItem.id" v-for="(subItem, subIndex) in item.questions" v-show="current_index == subIndex" :key="subIndex">
                                        <div style="width:100%;display: flex;align-items: center;"  v-if="subItem.examTimeValue">
                                        <el-progress style="width:80%;margin-right:16px;"  v-if="subItem.countDownTime != undefined && nodes_index == index && current_index == subIndex && subItem.countDownTime != 0" :show-text="false" :percentage="Number((((subItem.examTime * 1000)/ subItem.countDownTime) * 100).toFixed(0))"></el-progress>                                       
                                        <el-progress style="width:80%;margin-right:16px;"  v-else  :show-text="false" :percentage="0"></el-progress>                                       
                                        <div style="display: flex;">
                                               ( <van-count-down  v-if="subItem.countDownTime != undefined && nodes_index == index && current_index == subIndex && subItem.countDownTime != 0" @change="getTime"  :time="subItem.countDownTime" :auto-start="true" format="mm:ss" @finish="finish(item.questions,subItem)" >
                                                <template #default="timeData">    
                                                    <span v-if="timeData.minutes > 0" style="color:red;">{{timeData.minutes*60 +timeData.seconds }}</span>
                                                    <span v-else style="color:red;">{{ timeData.seconds }}</span>
                                                </template>
                                                </van-count-down>
                                                <span  style="color:red;" v-else> {{0}}</span>
                                                秒)
                                        </div>
                                        </div>
                                         <div class="node-question-title"  @dblclick="sign(subItem)">
                                            {{ subIndex + 1 }}.
                                            <span class="question-content" v-if="subItem.questionText" v-html="subItem.questionText"></span>
                                            <span class="tips">({{ subItem.score }}分)</span>
                                            <span class="node-qusetion-typeName">{{ subItem.questionTypeName }}</span>
                                            <img style="margin:0 8px;width:18px;height:18px;vertical-align: middle" @click="sign(subItem)" v-if="subItem.isSign" src="../../assets/exam/isSign-select.png" alt="">
                                            <img style="margin:0 8px;width:18px;height:18px;vertical-align: middle" @click="sign(subItem)" v-else src="../../assets/exam/isSign.png" alt="">
                                        </div>
                                        <template v-if="subItem.questionBaseTypeCode == 'compose'">
                                             <div class="node-question" style="padding-left:16px;" :disabled="subItem.isStop" :id="childItem.id" v-for="(childItem, childIndex) in subItem.childQuestions" :key="subIndex">
                                                 <div class="node-question-title"  @dblclick="sign(subItem)">
                                                    <span class="node-qusetion-typeName" :class="[childItem.isSign == true && 'select']" @click="sign(childItem)">{{ childItem.questionTypeName }}</span>
                                                         {{ childIndex + 1 }}.
                                                    <div v-if="childItem.questionText" v-html="childItem.questionText"></div>
                                                    <span style="margin-left: 8px">({{ childItem.score }}分)</span>
                                                </div>
                                                <template v-if="childItem.selectAnswers.length > 1 && childItem.questionBaseTypeCode == 'single'">
                                                    <el-radio-group v-model="childItem.answerText" style="width: 100%">
                                                        <div class="node-question-content" v-for="(item, index) in childItem.selectAnswerOrders" :key="index">
                                                            <el-radio  :label="item.selectValue"
                                                                ><span class="text" style="margin-right: 8px">{{ item.selectDisplayValue}}.</span>
                                                                <div class="text" style="display: inline-block;max-width: 785px;line-height: 20px;word-break: break-all;white-space: pre-wrap;"" v-html="item.selectText"></div
                                                            ></el-radio>
                                                        </div>
                                                    </el-radio-group>
                                                </template>
                                                <template v-if="childItem.questionBaseTypeCode == 'judge'">
                                                    <el-radio-group v-model="childItem.answerText" style="width: 100%">
                                                        <div class="node-question-content">
                                                            <el-radio class="text"  label="Y">正确</el-radio>
                                                            <el-radio  class="text" label="N">错误</el-radio>
                                                        </div>
                                                    </el-radio-group>
                                                </template>                                       
                                                <template v-if="childItem.questionBaseTypeCode == 'fill'">
                                                    <div class="node-question-answers" style="display:flex;">
                                                        <div style="display:flex;" v-for="(item, index) in childItem.answerText" :key="index">
                                                        <!-- <div style="display:flex;" v-for="(item, index) in childItem.answerTextList" :key="index"> -->
                                                            <div style="display:flex;"><el-input class="exam-input" v-model="item.text" ></el-input></div> <div style="display: flex;align-items: end;" v-if="index != childItem.standardAnswers.length - 1">、</div>
                                                        </div>
                                                    </div>
                                                </template>                                   
                                                <template v-if="childItem.questionBaseTypeCode == 'judge_correct'">
                                                    <div class="node-question-answers">                                        
                                                        <el-radio-group style="width: 100%"  v-model="childItem.answerText">
                                                            <div class="node-question-content" style="display: inline-flex; border: none">
                                                                <el-radio class="text" label="Y">正确</el-radio>
                                                                <el-radio class="text" label="N">错误</el-radio>
                                                                <input class="exam-input" v-if="childItem.answerText == 'N'" v-model="childItem.text" ></input>
                                                            </div>
                                                        </el-radio-group>
                                                    </div>
                                                </template>
                                                <template v-if="childItem.questionBaseTypeCode == 'answer'">
                                                    <div class="node-question-answers">
                                                        <el-input type="textarea" class="exam-input" v-model="childItem.answerText" name="" id="" cols="30" rows="1"></el-input>
                                                        <!-- <div style="display: inline-block" v-for="(item, index) in childItem.standardAnswers" v-html="item" :key="index"></div> -->
                                                    </div>
                                                </template>
                                                <template v-if="childItem.selectAnswers.length > 1 && childItem.questionBaseTypeCode == 'multi'">
                                                    <el-checkbox-group v-model="childItem.answerText" style="width: 100%">
                                                        <div class="node-question-content" v-for="(item, index) in childItem.selectAnswerOrders" :key="index">
                                                            <el-checkbox  @change="change"   :label="item.selectValue"
                                                                ><span class="text" style="margin-right: 8px">{{ item.selectDisplayValue }}</span>
                                                                <div class="text" style="display: inline-block;max-width: 785px;line-height: 20px;word-break: break-all;white-space: pre-wrap;" v-html="item.selectText"></div
                                                            ></el-checkbox>
                                                        </div>
                                                    </el-checkbox-group>                                            
                                                </template>
                                            </div>
                                        </template>
                                        <template v-if="subItem.selectAnswers.length > 1 && subItem.questionBaseTypeCode == 'single'">
                                            <el-radio-group v-model="subItem.answerText"  :disabled="subItem.isStop" style="width: 100%">
                                                <div class="node-question-content" v-for="(item, index) in subItem.selectAnswerOrders" :key="index">
                                                    <el-radio  :label="item.selectValue"
                                                    ><span class="text" style="margin-right: 8px">{{ item.selectDisplayValue}}.</span>
                                                                <div class="text" style="display: inline-block;max-width: 785px;line-height: 20px;word-break: break-all;white-space: pre-wrap;" v-html="item.selectText"></div
                                                    ></el-radio>
                                                </div>
                                            </el-radio-group>
                                        </template>
                                        <template v-if="subItem.questionBaseTypeCode == 'judge'">
                                            <el-radio-group  :disabled="subItem.isStop" v-model="subItem.answerText" style="width: 100%">
                                                <div class="node-question-content">
                                                    <el-radio class="text"  label="Y">正确</el-radio>
                                                    <el-radio class="text" label="N">错误</el-radio>
                                                </div>
                                            </el-radio-group>
                                        </template>                                       
                                        <template v-if="subItem.questionBaseTypeCode == 'fill'">
                                            <div class="node-question-answers" style="display:flex;">
                                                <div style="display:flex;" v-for="(item, index) in subItem.answerText" :key="index">
                                                <!-- <div style="display:flex;" v-for="(item, index) in subItem.answerTextList" :key="index"> -->
                                                    <div style="display:flex;"><el-input @input="answerInput(subItem)" :disabled="subItem.isStop" class="exam-input" v-model="item.text" ></el-input></div> <div style="display: flex;align-items: end;" v-if="index != subItem.standardAnswerCount - 1">、</div>
                                                </div>
                                            </div>
                                        </template>                                   
                                        <template v-if="subItem.questionBaseTypeCode == 'judge_correct'">
                                            <div class="node-question-answers">                                        
                                                <el-radio-group style="width: 100%" :disabled="subItem.isStop" v-model="subItem.answerText">
                                                    <div class="node-question-content" style="display: inline-flex; border: none">
                                                        <el-radio class="text" label="Y">正确</el-radio>
                                                        <el-radio class="text" label="N">错误</el-radio>
                                                        <input class="exam-input" :disabled="subItem.isStop" v-if="subItem.answerText == 'N'" v-model="subItem.text" ></input>
                                                    </div>
                                                </el-radio-group>
                                            </div>
                                        </template>
                                        <template v-if="subItem.questionBaseTypeCode == 'answer'">
                                            <div class="node-question-answers">
                                                <el-input type="textarea" class="exam-input" :disabled="subItem.isStop" v-model="subItem.answerText" name="" id="" cols="30" rows="1"></el-input>
                                                <!-- <div style="display: inline-block" v-for="(item, index) in subItem.standardAnswers" v-html="item" :key="index"></div> -->
                                            </div>
                                        </template>
                                        <template v-if="subItem.selectAnswers.length > 1 && subItem.questionBaseTypeCode == 'multi'">
                                            <el-checkbox-group v-model="subItem.answerText" style="width: 100%">
                                                <div class="node-question-content" v-for="(item, index) in subItem.selectAnswerOrders" :key="index">
                                                    <el-checkbox  @change="change"  :disabled="subItem.isStop" :label="item.selectValue"
                                                        ><span class="text" style="margin-right: 8px">{{ item.selectDisplayValue }}</span>
                                                        <div class="text" style="display: inline-block;max-width: 785px;line-height: 20px;word-break: break-all;white-space: pre-wrap;" v-html="item.selectText"></div
                                                    ></el-checkbox>
                                                </div>
                                            </el-checkbox-group>                                            
                                        </template>
                                        <div class="content-main-foot">
                                          <!-- v-if="!(ids == 0 && nodes_index == 0)"  -->
                                          <el-button class="prevbtn" v-if="!(subIndex == 0 && nodes_index == 0)" @click="preEvent(subItem,item.questions)">上一题</el-button>
                                          <el-button class="nextbtn" v-if="!((subIndex + 1 ) == item.questions.length && nodes_index + 1  >= detail.paperDetail.paperNodes.length)"  @click="nextEvent(item.questions,subItem)">下一题</el-button>
                                          <div class="nextbtn" style="margin-left:8px;"   @click.stop="save('submit')" v-if="!isStop && ((subIndex + 1 ) == item.questions.length && nodes_index + 1  == detail.paperDetail.paperNodes.length)">
                                                <img src="./../../assets/exam/icon-submit.png" alt="">
                                                <span  style="margin-left:12px;">交卷</span>
                                            </div>
                                          <!-- <el-button class="nextbtn" @click="nextEvent(item.questions,subItem)">下一题</el-button> -->
                                          <!-- <el-button class="nextbtn" v-if="!((subIndex + 1 ) == items.questions.length && nodes_index + 1  >= detail.nodes.length)"  @click="nextEvent(items.questions)">下一题</el-button> -->
                                        </div>
                                    </div>
                                 
                                </div>
                            </div>
                    </div>
                </el-col>
                <el-col style="height: 100%" :span="6">
                    <div class="main-right">
                      <div class="time-remaining">
                            剩余时间           
                            <van-count-down v-if="cutdownTime == Infinity" ref="countDown" :time="99999999"  format="DD 天 HH 时 mm 分 ss 秒" @finish="finishPaper()">
                                <template #default="timeData">
                                  <span class="block">{{ 99  }}</span>
                                  <span class="block">:</span>
                                  <span class="block">{{ 99  }}</span>
                                  <span class="block">:</span>
                                  <span class="block">{{ 99  }}</span>
                                </template>
                            </van-count-down>
                            <van-count-down v-else ref="countDown" :time="cutdownTime" :auto-start="true"  @change="getPaperTime"  format="DD 天 HH 时 mm 分 ss 秒" @finish="finishPaper()">
                                <template #default="timeData">
                                  <span class="block">{{ timeData.hours < 10 ? '0' + timeData.hours :timeData.hours  }}</span>
                                  <span class="block">:</span>
                                  <span class="block">{{ timeData.minutes < 10 ? '0' + timeData.minutes :timeData.minutes  }}</span>
                                  <span class="block">:</span>
                                  <span class="block">{{ timeData.seconds < 10 ? '0' + timeData.seconds :timeData.seconds  }}</span>
                                </template>
                            </van-count-down>
                        </div>    
                         <div class="examFaceModal" v-if="isShowFace" >
                            <faceModal  v-if="isShowFace" :detail="faceDetail"></faceModal>
                        </div>
                         <div class="answers-card">
                            <div class="card-head">
                                答题卡
                            </div>
                            <div class="card-title">
                                <div  style="display: inline-flex;align-items: center;width:100%;height:100%;justify-content: space-around;padding:0 25px;">
                                    <div  class="title-item" >                                     
                                        <div class="noAnswer mg-rt"></div>
                                        <span>未答题</span>
                                    </div>
                                   <div class="title-item" >                                       
                                        <div class="isAnswer mg-rt"></div>
                                        <span>已答题</span>
                                   </div>
                                    <div class="title-item" >
                                        <div class="example-item mg-rt" >
                                            <div class="corner"></div>
                                            <div class="item-content">
                                                <div class="item-centre">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="example-item finish mg-rt" >
                                            <div class="corner"></div>
                                            <div class="item-content">
                                                <div class="item-centre">
                                                </div>
                                            </div>
                                        </div>
                                        <span>已标记</span>
                                       
                                     </div>
                                     <div class="title-item">
                                          <div class="com-item finish mg-rt" >
                                            <div class="item-content">
                                                <div class="item-centre">
                                                </div>
                                            </div>
                                        </div>
                                        <span>组合题</span>
                                     </div>
                                </div>
                            </div>
                            <div class="question-modal" v-for="(itm, index) in detail.paperDetail.paperNodes" :key="index">                                
                                <div class="modal-title"><span>{{ itm.examPaperNode.paperNodeName }}</span><span class="tips"> ({{ `${itm.examPaperNode.questionNum}题` }})</span></div>
                                <div class="modal-content"  >
                                    <template v-for="(subItem, subIndex) in itm.questions" >                                     
                                        <a class="question-item"  v-if="subItem.questionBaseTypeCode != 'compose'"  @click="goQuestion(index,subIndex,subItem)"  :class="[((subItem.answerText != undefined && !subItem.fillAnswer && subItem.questionBaseTypeCode != 'compose') || (subItem.questionBaseTypeCode == 'compose' && !subItem.fillAnswer) ||  (subItem.questionBaseTypeCode == 'voice' && !subItem.fillAnswer)) && 'finish'  ]"  :key="subIndex">                                    
                                            <div class="item-content">
                                            <div class="corner" v-if="subItem.isSign"></div>
                                                <div class="item-centre">
                                                    {{subIndex + 1 }}
                                                </div>                                             
                                            </div>
                                        </a>
                                        <div  :key="subIndex"  v-else  style="width: 100%;display: flex;">
                                            <div  style="width: 100%;display: flex;">
                                               <a class="compose-item" @click="clickQuestion(subItem)"  :class="[((subItem.answerText != undefined && !subItem.fillAnswer && subItem.questionBaseTypeCode != 'compose') || (subItem.questionBaseTypeCode == 'compose' && !subItem.fillAnswer) ||  (subItem.questionBaseTypeCode == 'voice' && !subItem.fillAnswer)) && 'finish'  ]">
                                                    <div class="item-content">
                                                        <div class="corner" v-if="subItem.isSign"></div>
                                                        <div class="item-centre">
                                                             {{subIndex + 1 }}
                                                        </div>                                             
                                                    </div>
                                               </a>
                                               <template v-if="subItem.childQuestions && subItem.childQuestions.length">
                                                   <a class="question-item" @click="clickQuestion(childItem)"  :class="[((childItem.answerText != undefined && !childItem.fillAnswer && childItem.questionBaseTypeCode != 'compose') || (childItem.questionBaseTypeCode == 'compose' && !childItem.fillAnswer) ||  (childItem.questionBaseTypeCode == 'voice' && !childItem.fillAnswer)) && 'finish'  ]"   v-for="(childItem,childIndex) in subItem.childQuestions" :key="childIndex">
                                                     <div class="item-content">
                                                        <div class="corner" v-if="childItem.isSign"></div>
                                                            <div class="item-centre" @click="warnItem(childItem)">
                                                                {{childIndex + 1 }}
                                                            </div>                                             
                                                        </div>
                                                   </a>
                                               </template>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>                                                                            
                        </div>
                           <!-- <div class="btn-submit"  @click="save('submit')" v-if="!isStop">
                            <img src="./../../assets/exam/icon-submit.png" alt="">
                            <span  style="margin-left:12px;">交卷</span>
                        </div> -->
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import Api from "@/api/paper";
import axios from "axios";
import faceModal from "@/components/examFace";
import Editor from "@/components/wangEditor/wangEditor";
import { useFullscreen } from "@vueuse/core";
const { isFullscreen, enter } = useFullscreen();
export default {
  components: {
    faceModal,
    Editor,
  },
  data() {
    return {
      count: 0,
      noOperationNum: 0,
      noOperationCount: 0,
      noOperation: false,
      isFullscreen,
      saveDialogVisible: false,
      submitDialogVisible: false,
      params: {},
      detail: {
        paperDetail: {
          examPaper: {},
        },
        exam: {},
        paperNodes: [],
      },
      gradeScore: "",
      gradeRate: 0,
      canSeeGrade: true,
      examTime: 0,
      sizeClass: "normal",
      fontValue: 0,
      answerDialogVisible: false,
      unfinishedNum: 0,
      nodes_index: 0,
      isSave: false,
      hidden: false,
      result: "",
      dialogVisible: false,
      isSignNum: 0,
      current_index: 0, //当前题目下标
      startTime: "",
      examGradeUid: "",
      userInfo: {},
      cutdownTime: Infinity, //答卷倒计时时长
      selectType: ["A", "B", "C", "D", "E", "F", "G", "H"],
      sortList: ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十"],
      pauseDialogVisible: false,
      isPause: false,
      isPauseText: "",
      cutdownNum: 11, //答卷倒计时时长
      isExistsDetail: false,
      isConnecting: JSON.parse(sessionStorage.getItem("isConnecting")),
      isConnectingInterval: null,
      isStop: false,
      autosave: null,
      useExamTime: 0,
      isAllowViewPaperNow: true,
      isDisplayResult: false,
      isPass: false,
      isNeedJudge: false,
      fullscreen: false, //全屏，fasle默认不是全屏，true为全屏
      faceDetail: {},
      isShowFace: false,
      imgUrl: "",
      submitFailDialogVisible:false
    };
  },
  methods: {
    enter,
    enterFullScreen() {
      if (typeof this.isFullscreen == "object") {
        if (!this.isFullscreen.value) {
          this.enter();
        }
      } else {
        if (!this.isFullscreen) {
          this.enter();
        }
      }
    },
    closeSubmitFailDialogVisible(){
       this.submitFailDialogVisible=false;
    },
    formatTooltip(val) {
      let text = " ";
      switch (val) {
        case 0:
          text = "正常";
          break;
        case 1:
          text = "中等";
          break;
        case 2:
          text = "大";
          break;
        case 3:
          text = "超大";
          break;
        default:
          text = "正常";

          break;
      }
      this.$forceUpdate();

      return text;
    },
    fontChange(size) {
      switch (size) {
        case 0:
          this.sizeClass = "normal";
          break;
        case 1:
          this.sizeClass = "center";
          break;
        case 2:
          this.sizeClass = "big";
          break;
        case 3:
          this.sizeClass = "oversized";
          break;
        default:
          this.sizeClass = "normal";

          break;
      }
      this.$forceUpdate();
    },
    finishPaper() {
      this.save("finish");
    },
    getPaperTime(item) {
      this.useExamTime =
        this.detail.examGrade.allowExamTime -
        item.hours * 3600 -
        item.minutes * 60 -
        item.seconds;
      sessionStorage.setItem("cutdownTime", this.useExamTime);
    },
    clearAutosave() {
      clearInterval(this.autosave);
    },
    change() {
      e.fillAnswer = false;
      this.$forceUpdate();
    },
    //   即时通讯模块
    screenNoSendMessage(item) {
      // 筛选出不是发送消息的元素
      if (item.commandName != "SendMessage") {
        return item;
      } else {
        return false;
      }
    },
    screenForceToSubmit(item) {
      //筛选出强行提交的元素
      if (item.commandName == "ForceToSubmit") {
        return item;
      } else {
        return false;
      }
    },
    screenSendMessage(item) {
      // 筛选出发送消息的元素
      if (item.commandName == "SendMessage") {
        return item;
      } else {
        return false;
      }
    },
    screenPauseAndEndPauseExam(item) {
      //筛选出暂停和继续考试的元素
      if (
        item.commandName == "EndPauseExam" ||
        item.commandName == "PauseExam"
      ) {
        return item;
      } else {
        return false;
      }
    },
    sortTime(property) {
      return function (a, b) {
        let value1 = a[property];
        let value2 = b[property];
        return new Date(value1).getTime() - new Date(value2).getTime();
      };
    },
    getOperationList(params) {
      params.commandStatusCode = "wait";
      Api.operationList(params)
        .then((res) => {
          // ForceToSubmit;
          // PauseExam;
          // EndPauseExam;
          // SendMessage;
          // res.data
          if (res.data.length > 0) {
            let operationList = [];
            if (res.data.filter(this.screenNoSendMessage)) {
              let noMessageList = res.data.filter(this.screenNoSendMessage);
              if (noMessageList.filter(this.screenForceToSubmit).length != 0) {
                operationList.push(
                  noMessageList.filter(this.screenForceToSubmit)[0]
                );
              } else {
                if (noMessageList.filter(this.screenPauseAndEndPauseExam)) {
                  operationList.push(
                    ...noMessageList.filter(this.screenPauseAndEndPauseExam)
                  );
                }
              }
            }
            if (res.data.filter(this.screenSendMessage)) {
              operationList.push(...res.data.filter(this.screenSendMessage));
            }

            if (operationList.length > 1) {
              operationList.sort(this.sortTime("creationTime"));
            }
            for (let i = 0; i < operationList.length; i++) {
              switch (operationList[i].commandName) {
                case "ForceToSubmit":
                  console.warn("强行提交");
                  this.save("force");
                  break;
                case "PauseExam":
                  console.warn("暂停考试");
                  this.pauseExam(operationList[i].examGradeUid);
                  this.pauseTime();

                  break;
                case "EndPauseExam":
                  console.warn("解除考试暂停");
                  this.resumeExam(operationList[i].examGradeUid);
                  this.continueExam();
                  break;
                case "SendMessage":
                  console.warn("发送消息", operationList[i]);
                  // alert(operationList[i].commandPara);
                  this.$alert(operationList[i].commandPara, "消息通知", {
                    confirmButtonText: "确定",
                  });
                  break;
              }
            }
          }
        })
        .catch((err) => {
          console.warn(err);
        });
    },
    updateExamStatus(id) {
      let params = [];
      if (id) {
        params.push(id);
      }
      Api.updateStatus(params)
        .then((res) => {
          console.warn(res);
        })
        .catch((err) => {
          console.warn(err);
        });
    },
    pauseExam(gradeUid) {
      Api.pauseExam(gradeUid)
        .then((res) => {
          (this.pauseDialogVisible = true), (this.isPause = true);
          console.warn(res);
        })
        .catch((err) => {
          console.warn(err);
        });
    },
    resumeExam(gradeUid) {
      Api.resumeExam(gradeUid)
        .then((res) => {
          (this.pauseDialogVisible = false), (this.isPause = false);

          console.warn(res);
        })
        .catch((err) => {
          console.warn(err);
        });
    },
    //
    closePaper() {
      window.opener = null;
      window.open("", "_self");
      window.close();
    },
    goAnswerPaper() {
      this.answerDialogVisible = false;
      this.$router.replace(
        `/exam/paper/detail?examUid=${this.examUid}&examGradeUid=${this.examGradeUid}`
      );
    },
    continueExam() {
      this.pauseDialogVisible = false;
      this.isPause = false;
      if (this.cutdownTime != Infinity) {
        this.$refs.countDown.start();
      }
    },
    pauseTime() {
      if (this.cutdownTime != Infinity) {
        this.$refs.countDown.pause();
      }
    },
    pause() {
      if (this.cutdownTime != Infinity) {
        this.pauseTime();
      }
      this.pauseDialogVisible = true;
      this.save("forceSave");
    },
    checkQuestion() {
      this.isSignNum = 0;
      this.dialogVisible = true;
      this.unfinishedNum = 0;

      this.detail.paperDetail.paperNodes.forEach((item) => {
        item.questions.forEach((subItem) => {
          if (subItem.fillAnswer && !subItem.isStop) {
            this.unfinishedNum += 1;
          } else if (subItem.fillAnswer && subItem.isStop) {
            this.unfinishedNum += 1;
          }
          if (subItem.isSign) {
            this.isSignNum += 1;
          }
        });
      });
    },
    handleClose(done) {
      done();
    },
    getTime(item) {
      console.warn(item);
      if (item.minutes != 0) {
        this.detail.paperDetail.paperNodes[this.nodes_index].questions[
          this.current_index
        ].examTime = item.minutes * 60 + item.seconds;
      } else {
        this.detail.paperDetail.paperNodes[this.nodes_index].questions[
          this.current_index
        ].examTime = item.seconds;
      }
    },
    finish(list, item) {
      item.isStop = true;
      this.nextEvent(list, item);
      //每题倒计时
    },
    closePage() {
      this.$router.replace("/exam/list");
    },
    preEvent(item) {
      let base = JSON.parse(sessionStorage.getItem("baseDetail"));
      base.paperDetail.paperNodes[this.nodes_index].questions[
        this.current_index
      ].examTime = item.examTime;
      base.paperDetail.paperNodes[this.nodes_index].questions[
        this.current_index
      ].isStop = item.isStop;
      base.paperDetail.paperNodes[this.nodes_index].questions[
        this.current_index
      ].countDownTime = item.examTime * 1000;
      if (item.answerText) {
        base.paperDetail.paperNodes[this.nodes_index].questions[
          this.current_index
        ].answerText = item.answerText;
      }
      if (this.current_index > 0) {
        this.current_index = this.current_index - 1;
      }
      if (this.current_index == 0 && this.nodes_index > 0) {
        this.current_index =
          this.detail.paperDetail.paperNodes[this.nodes_index - 1].questions
            .length - 1;
        this.nodes_index = this.nodes_index - 1;
      }
      sessionStorage.setItem("baseDetail", JSON.stringify(base));
      this.changValue(
        this.detail.paperDetail.paperNodes[this.nodes_index].questions[
          this.current_index
        ],
        base.paperDetail.paperNodes[this.nodes_index].questions[
          this.current_index
        ]
      );
      this.$forceUpdate();
    },
    //  下一题
    nextEvent(list, item) {
      let base = JSON.parse(sessionStorage.getItem("baseDetail"));
      base.paperDetail.paperNodes[this.nodes_index].questions[
        this.current_index
      ].isStop = item.isStop;
      base.paperDetail.paperNodes[this.nodes_index].questions[
        this.current_index
      ].examTime = item.examTime;
      base.paperDetail.paperNodes[this.nodes_index].questions[
        this.current_index
      ].countDownTime = item.examTime * 1000;
      if (item.answerText) {
        base.paperDetail.paperNodes[this.nodes_index].questions[
          this.current_index
        ].answerText = item.answerText;
      }
      sessionStorage.setItem("baseDetail", JSON.stringify(base));
      if (this.current_index < list.length - 1) {
        this.current_index = this.current_index + 1;
        this.changValue(
          this.detail.paperDetail.paperNodes[this.nodes_index].questions[
            this.current_index
          ],
          base.paperDetail.paperNodes[this.nodes_index].questions[
            this.current_index
          ]
        );
        return;
      }
      if (
        this.current_index + 1 > list.length - 1 &&
        this.nodes_index < this.detail.paperDetail.paperNodes.length - 1
      ) {
        this.nodes_index = this.nodes_index + 1;
        this.current_index = 0;
      }
      this.changValue(
        this.detail.paperDetail.paperNodes[this.nodes_index].questions[
          this.current_index
        ],
        base.paperDetail.paperNodes[this.nodes_index].questions[
          this.current_index
        ]
      );
      this.$forceUpdate();
    },
    changValue(a, b) {
      if (b.examTimeValue && b.countDownTime == 0) {
        b.isStop = true;
      }
      a.examTime = b.examTime;
      a.isStop = b.isStop;
      a.countDownTime = b.countDownTime;
      if (b.answerText) {
        a.answerText = b.answerText;
      }
    },
    goQuestion(ids, index, subItem) {
      let base = JSON.parse(sessionStorage.getItem("baseDetail"));
      base.paperDetail.paperNodes[this.nodes_index].questions[
        this.current_index
      ].isStop =
        this.detail.paperDetail.paperNodes[this.nodes_index].questions[
          this.current_index
        ].isStop;
      base.paperDetail.paperNodes[this.nodes_index].questions[
        this.current_index
      ].examTime =
        this.detail.paperDetail.paperNodes[this.nodes_index].questions[
          this.current_index
        ].examTime;
      base.paperDetail.paperNodes[this.nodes_index].questions[
        this.current_index
      ].countDownTime =
        this.detail.paperDetail.paperNodes[this.nodes_index].questions[
          this.current_index
        ].examTime * 1000;
      if (
        this.detail.paperDetail.paperNodes[this.nodes_index].questions[
          this.current_index
        ].answerText
      ) {
        base.paperDetail.paperNodes[this.nodes_index].questions[
          this.current_index
        ].answerText =
          this.detail.paperDetail.paperNodes[this.nodes_index].questions[
            this.current_index
          ].answerText;
      }
      sessionStorage.setItem("baseDetail", JSON.stringify(base));
      this.current_index = index;
      this.nodes_index = ids;
      this.changValue(
        this.detail.paperDetail.paperNodes[this.nodes_index].questions[
          this.current_index
        ],
        base.paperDetail.paperNodes[this.nodes_index].questions[
          this.current_index
        ]
      );
      this.$forceUpdate();
    },
    checkValue(obj) {
      return obj.text != " " && obj.text != "";
    },
    answerInput(item) {
      if (item.answerText.every(this.checkValue)) {
        item.fillAnswer = false;
      } else {
        item.fillAnswer = true;
      }
      this.$forceUpdate();
    },
    sign(item) {
      if (item.isSign != undefined) {
        item.isSign = !item.isSign;
      } else {
        item.isSign = true;
      }
      this.$forceUpdate();
    },
    save(type) {
      if (!this.pauseDialogVisible) {
        this.exitScreen(); //退出全屏
      }
      let Text = type == "save" ? "保存" : "提交";
      this.unfinishedNum = 0;
      let params = {
        examAnswers: [],
      };
      this.detail.paperDetail.paperNodes.forEach((item) => {
        item.questions.forEach((subItem) => {
          if (subItem.fillAnswer) {
            this.unfinishedNum += 1;
            if (subItem.questionBaseTypeCode == "fill") {
              let obj = {
                questionUid: subItem.id,
                isSetBookMark: subItem.isSign ? true : false,
              };
              let textArr = [];
              console.warn(subItem.answerText);
              if (subItem.answerText.length > 1) {
                subItem.answerText.forEach((item) => {
                  if (item.text != "") {
                    item.text = item.text.trim();
                  }
                  textArr.push(item.text);
                });
                console.warn("textArr", textArr);
                obj.answerText = textArr.join("$contentsplit$");
              } else {
                obj.answerText = subItem.answerText[0].text;
              }
              params.examAnswers.push(obj);
            }
          } else if (subItem.fillAnswer == false) {
            let obj = {
              questionUid: subItem.id,
              isSetBookMark: subItem.isSign ? true : false,
            };
            console.warn(subItem.answerText);

            if (Array.isArray(subItem.answerText)) {
              if (subItem.answerText.length == 0) {
                obj.answerText = "";
              } else if (subItem.answerText.length == 1) {
                obj.answerText = subItem.answerText[0];
              } else {
                obj.answerText = subItem.answerText.join("$contentsplit$");
              }
            } else {
              obj.answerText = subItem.answerText;
            }
            if (
              subItem.questionBaseTypeCode == "judge_correct" &&
              subItem.answerText == "N"
            ) {
              if (subItem.text) {
                obj.answerText = subItem.text;
              }
            }
            if (subItem.questionBaseTypeCode == "fill") {
              let textArr = [];
              console.warn(subItem.answerText);
              if (subItem.answerText.length > 1) {
                subItem.answerText.forEach((item) => {
                  textArr.push(item.text);
                });
                console.warn("textArr", textArr);
                obj.answerText = textArr.join("$contentsplit$");
              } else {
                obj.answerText = subItem.answerText[0].text;
              }
            }
            params.examAnswers.push(obj);
          }
          if (subItem.questionBaseTypeCode == "compose") {
            subItem.childQuestions.forEach((childItem) => {
              if (childItem.fillAnswer == false) {
                let obj = {
                  questionUid: childItem.id,
                  isSetBookMark: childItem.isSign ? true : false,
                };

                if (Array.isArray(childItem.answerText)) {
                  if (childItem.answerText.length == 0) {
                    obj.answerText = "";
                  } else if (childItem.answerText.length == 1) {
                    obj.answerText = childItem.answerText[0];
                  } else {
                    obj.answerText =
                      childItem.answerText.join("$contentsplit$");
                  }
                } else {
                  obj.answerText = childItem.answerText;
                }
                if (
                  childItem.questionBaseTypeCode == "judge_correct" &&
                  childItem.answerText == "N"
                ) {
                  if (childItem.text) {
                    obj.answerText = childItem.text;
                  }
                }
                if (childItem.questionBaseTypeCode == "fill") {
                  let textArr = [];
                  if (childItem.answerText.length > 1) {
                    childItem.answerText.forEach((item) => {
                      textArr.push(item.text);
                    });
                    obj.answerText = textArr.join("$contentsplit$");
                  } else {
                    obj.answerText = childItem.answerText[0].text;
                  }
                }
                params.examAnswers.push(obj);
              }
            });
          }
          params.noAnswerQuestionNum = this.unfinishedNum;
        });
      });
      if (type == "submit") {
        params.examArrangeUid = this.detail.examGrade.examArrangeUid;
        if (this.detail.exam.forbitSubmitBeforeTime != 0) {
          if (this.useExamTime < this.detail.exam.forbitSubmitBeforeTime) {
            this.$message.error(
              `至少作答${
                this.detail.exam.forbitSubmitBeforeTime / 60
              }分钟后交卷`
            );
            return;
          }
        }
      }
      if (type == "finish") {
        params.examArrangeUid = this.detail.examGrade.examArrangeUid;
        this.isStop = true;
        Api.submitPaper(this.detail.exam.id, this.detail.examGrade.id, params)
          .then((res) => {
            this.examGradeUid = res.data.examGradeUid;
            this.gradeScore = res.data.gradeScore;
            this.isAllowViewPaperNow = res.data.isAllowViewPaperNow;
            this.canSeeGrade = res.data.canSeeGrade;
            this.isPass = res.data.isPass;
            this.isDisplayResult = res.data.isDisplayResult;
            if (res.data.gradeRate != 0) {
              this.gradeRate = res.data.gradeRate.toFixed(2);
            }

            this.answerDialogVisible = true;
            this.$forceUpdate();
            if (localStorage.getItem("examList").length != 0) {
              let list = JSON.parse(localStorage.getItem("examList"));
              let arr = list;
              for (let i = 0; i < arr.length; i++) {
                if (arr[i].examGrade.id == this.detail.examGrade.id) {
                  arr.splice(i, 1);
                }
              }
              localStorage.setItem("examList", JSON.stringify(arr));
            }
          })
          .catch((err) => {
            console.warn(err);
            //this.answerDialogVisible = true;
              if(err.response&&err.response.status!=403){
                  this.submitFailDialogVisible=true;
              }
              if(!err.response){
                  this.submitFailDialogVisible=true;
              }
          });
        return;
      }
      if (type == "force") {
        Api.submitPaper(this.detail.exam.id, this.detail.examGrade.id, params)
          .then((res) => {
            this.gradeScore = res.data.gradeScore;
            this.answerDialogVisible = true;
            this.examGradeUid = res.data.examGradeUid;
            this.isAllowViewPaperNow = res.data.isAllowViewPaperNow;
            this.canSeeGrade = res.data.canSeeGrade;
            this.isNeedJudge = res.data.isNeedJudge;
            this.isPass = res.data.isPass;
            this.isDisplayResult = res.data.isDisplayResult;
            if (res.data.gradeRate != 0) {
              this.gradeRate = res.data.gradeRate.toFixed(2);
            }

            if (localStorage.getItem("examList").length != 0) {
              let list = JSON.parse(localStorage.getItem("examList"));
              let arr = list;
              for (let i = 0; i < arr.length; i++) {
                if (arr[i].examGrade.id == this.detail.examGrade.id) {
                  arr.splice(i, 1);
                }
              }
              localStorage.setItem("examList", JSON.stringify(arr));
            }
            window.onblur = null;
            window.onfocus = null;

            this.signalr.invoke("ClearExam").catch(function (err) {
              return console.error(err.toString());
            });
          })
          .catch((err) => {
            console.warn(err);
            //this.answerDialogVisible = true;
            if(err.response&&err.response.status!=403){
                this.submitFailDialogVisible=true;
            }
            if(!err.response){
                this.submitFailDialogVisible=true;
            }
          });
      } else if (type == "forceSave") {
        Api.savePaper(this.detail.exam.id, this.detail.examGrade.id, params)
          .then((res) => {
            console.warn(res);
          })
          .catch((err) => {
            console.warn(err);
          });
      } else {
        let saveText = "";
        if (this.unfinishedNum) {
          saveText = `您还有${this.unfinishedNum}题未完成,确定${Text}吗?`;
        } else {
          saveText = `确定${Text}吗?`;
        }
        this.$confirm(`${saveText}`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            if (type == "save") {
              Api.savePaper(
                this.detail.exam.id,
                this.detail.examGrade.id,
                params
              )
                .then((res) => {
                  this.$message.success("保存成功");
                  console.warn(res);
                })
                .catch((err) => {
                  console.warn(err);
                  if(err.response&&err.response.status!=403){
                      this.submitFailDialogVisible=true;
                   }
                   if(!err.response){
                     this.submitFailDialogVisible=true;
                   }
                });
            } else {
              console.warn("正常交卷");
              Api.submitPaper(
                this.detail.exam.id,
                this.detail.examGrade.id,
                params
              )
                .then((res) => {
                  this.clearTimer();
                  this.examGradeUid = res.data.examGradeUid;
                  this.gradeScore = res.data.gradeScore;
                  this.isAllowViewPaperNow = res.data.isAllowViewPaperNow;
                  this.canSeeGrade = res.data.canSeeGrade;
                  this.isNeedJudge = res.data.isNeedJudge;
                  this.isPass = res.data.isPass;
                  this.isDisplayResult = res.data.isDisplayResult;
                  if (res.data.gradeRate != 0) {
                    this.gradeRate = res.data.gradeRate.toFixed(2);
                  }

                  this.answerDialogVisible = true;
                  this.$forceUpdate();
                  if (localStorage.getItem("examList").length != 0) {
                    let list = JSON.parse(localStorage.getItem("examList"));
                    let arr = list;
                    for (let i = 0; i < arr.length; i++) {
                      if (arr[i].examGrade.id == this.detail.examGrade.id) {
                        arr.splice(i, 1);
                      }
                    }
                    localStorage.setItem("examList", JSON.stringify(arr));
                  }
                  window.onblur = null;
                  window.onfocus = null;
                  this.signalr.invoke("ClearExam").catch(function (err) {
                    return console.error(err.toString());
                  });
                })
                .catch((err) => {
                  console.warn(err);
                  //this.answerDialogVisible = true;
                  if(err.response&&err.response.status!=403){
                      this.submitFailDialogVisible=true;
                   }
                   if(!err.response){
                     this.submitFailDialogVisible=true;
                   }
                });
            }
          })
          .catch(() => {});
      }
    },
    showSaveModal() {
      Api.savePaper(this.detail.exam.id, this.detail.examGrade.id, this.params)
        .then((res) => {
          // this.$message.success('保存成功');
          console.warn(res);
          this.saveDialogVisible = false;
          this.isSave = true;
          let time = setTimeout(() => {
            this.hidden = true;
            clearTimeout(time);
            let hidden = setTimeout(() => {
              this.isSave = false;
              this.hidden = false;
              clearTimeout(hidden);
            }, 1000);
          }, 3000);
        })
        .catch((err) => {
          console.warn(err);
          this.$message.erroe("保存失败");
          this.saveDialogVisible = false;
        });
    },
    showSubmitModal() {
      Api.submitPaper(
        this.detail.exam.id,
        this.detail.examGrade.id,
        this.params
      )
        .then((res) => {
          this.examGradeUid = res.data.examGradeUid;
          this.gradeScore = res.data.gradeScore;
          this.isAllowViewPaperNow = res.data.isAllowViewPaperNow;
          this.canSeeGrade = res.data.canSeeGrade;
          this.isNeedJudge = res.data.isNeedJudge;
          this.isPass = res.data.isPass;
          this.isDisplayResult = res.data.isDisplayResult;
          if (res.data.gradeRate != 0) {
            this.gradeRate = res.data.gradeRate.toFixed(2);
          }

          this.answerDialogVisible = true;
          this.$forceUpdate();
          if (localStorage.getItem("examList").length != 0) {
            let list = JSON.parse(localStorage.getItem("examList"));
            let arr = list;
            for (let i = 0; i < arr.length; i++) {
              if (arr[i].examGrade.id == this.detail.examGrade.id) {
                arr.splice(i, 1);
              }
            }
            localStorage.setItem("examList", JSON.stringify(arr));
          }
          window.onblur = null;
          window.onfocus = null;
          this.submitDialogVisible = false;

          this.signalr.invoke("ClearExam").catch(function (err) {
            return console.error(err.toString());
          });
        })
        .catch((err) => {
          this.submitDialogVisible = false;
          this.$message.error("考试提交失败,请勿关闭考试页面");
          console.warn(err);
        });
    },
    checkAnswer(obj) {
      return obj.fillAnswer == false;
    },
    monitorFace(res) {
      //是否开启人脸识别
      if (res.data.exam.isOpenCameraInvigilate) {
        this.faceDetail = {
          examName: res.data.exam.examName,
          examArrangeUid: res.data.examArrange.id,
          examGradeUid: res.data.examGrade.id,
          beginTimeFormat: res.data.examArrange.beginTime,
          endTimeFormat: res.data.examArrange.endTime,
          examTime: res.data.exam.examTime,
          maxExamNum: res.data.exam.maxExamNum,
          openCameraInvigilateInterval:
            res.data.exam.openCameraInvigilateInterval,
        };
        this.isShowFace = true;
      }
    },
    requestDetail() {
      let joinParams = {};
      if (this.$route.query.examArrangeUid && this.$route.query.examUid) {
        this.examUid = this.$route.query.examUid;
        this.examArrangeUid = this.$route.query.examArrangeUid;
        if (
          this.$route.query.faceVerificationKey &&
          this.$route.query.examGradeUid
        ) {
          joinParams.faceVerificationKey =
            this.$route.query.faceVerificationKey;
          joinParams.examGradeUid = this.$route.query.examGradeUid;
        }
      }
      if (sessionStorage.getItem("baseDetail")) {
        this.detail = JSON.parse(sessionStorage.getItem("baseDetail"));
        if (!this.detail.exam.isOpenBook) {
          this.monitoringScreen(this.detail);
        }
        if (this.detail.exam.isNoOperationExamSubmit) {
          if (this.detail.exam.noOperationExamSubmit) {
            this.initMouse(this.detail.exam.noOperationExamSubmit); //无操作初始化
          }
        }
      } else {
        Api.getDetail(this.examUid, this.examArrangeUid, joinParams).then(
          (res) => {
            let params = {
              examGradeUid: res.data.examGrade.id,
              examUid: res.data.examGrade.examUid,
              userId: res.data.userInfo.userUid,
            };
            this.monitorFace(res);
            if (res.data.examGrade.allowExamTime) {
              this.examTime = (res.data.examGrade.allowExamTime / 60).toFixed(
                1
              );
            }
            this.getOperationList(params);
            console.warn("即时通讯状态", this.isConnecting);
            if (!this.isConnecting) {
              let params = {
                ExamUid: res.data.examGrade.examUid,
                ExamArrangeUid: res.data.examGrade.examArrangeUid,
                ExamGradeUid: res.data.examGrade.id,
                IsOpenBook: res.data.exam.isOpenBook,
                ClientType: 0, // 客户端类型  0：Pc   1：移动端
                MethodName: "receiveMessages",
                SourceType: "examing",
                Message:
                  "有新的开卷考试或者已经存在闭卷考试，当前考试即将关闭。",
              };
              console.warn(JSON.stringify(params));
              this.signalr
                .invoke("ExamLimitMessage", JSON.stringify(params))
                .catch(function (err) {
                  return console.error(err.toString());
                });
            } else {
              let _this = this;
              if (res.data.exam.isRealTimeControll) {
                this.isConnectingInterval = setInterval(() => {
                  let params = {
                    examGradeUid: _this.detail.examGrade.id,
                    examUid: _this.detail.examGrade.examUid,
                    userId: _this.detail.userInfo.userUid,
                  };
                  _this.getOperationList(params);
                }, 60000);
              }
            }
            if (res.data.exam.autoSaveToServer) {
              this.autosave = setInterval(() => {
                this.save("forceSave");
              }, 300000);
            }
            if (!res.data.exam.isOpenBook) {
              this.monitoringScreen(res.data);
            }
            if (res.data.exam.isNoOperationExamSubmit) {
              if (res.data.exam.noOperationExamSubmit) {
                this.initMouse(res.data.exam.noOperationExamSubmit); //无操作初始化
              } else {
                console.warn("无操作时间未设置");
              }
            }
            if (res.data.examGrade.allowExamTime != 0) {
              if (!res.data.examGrade.examTime) {
                this.cutdownTime = res.data.examGrade.allowExamTime * 1000;
              } else {
                this.cutdownTime =
                  res.data.examGrade.allowExamTime * 1000 -
                  res.data.examGrade.examTime * 1000;
              }
            }
            this.$forceUpdate();
            if (
              localStorage.getItem("examList") &&
              localStorage.getItem("examList").length != 0
            ) {
              let list = JSON.parse(localStorage.getItem("examList"));
              let arr = list;
              for (let i = 0; i < arr.length; i++) {
                if (arr[i].examGrade.id == res.data.examGrade.id) {
                  this.detail = arr[i];
                  this.isExistsDetail = true;
                  if (this.detail.examGrade.gradeStatusCode == "pause") {
                    this.pauseDialogVisible = true;
                    this.isPause = true;
                    this.pauseTime();
                    return;
                  }
                  sessionStorage.setItem(
                    "baseDetail",
                    JSON.stringify(this.detail)
                  );
                  // if (this.detail.examGrade.allowExamTime != 0) {
                  //     if (sessionStorage.getItem('cutdownTime')) {
                  //         this.cutdownTime = this.detail.examGrade.allowExamTime * 1000 - sessionStorage.getItem('cutdownTime') * 1000;
                  //     } else {
                  //         if (!this.detail.examGrade.examTime) {
                  //             this.cutdownTime = this.detail.examGrade.allowExamTime * 1000;
                  //         } else {
                  //             this.cutdownTime = this.detail.examGrade.allowExamTime * 1000 - this.detail.examGrade.examTime * 1000;
                  //         }
                  //     }
                  // }
                  return;
                }
              }
            }
            res.data.paperDetail.paperNodes.forEach((item) => {
              item.questions.forEach((subItem) => {
                if (subItem.examTime) {
                  subItem.countDownTime = subItem.examTime * 1000;
                }
                if (
                  subItem.questionBaseTypeCode == "fill" &&
                  subItem.standardAnswerCount > 0
                ) {
                  subItem.answerText = [];
                  for (let i = 0; i < subItem.standardAnswerCount; i++) {
                    let obj = {
                      text: " ",
                    };
                    subItem.answerText.push(obj);
                  }
                  subItem.fillAnswer = true;
                }
                if (subItem.questionBaseTypeCode == "compose") {
                  subItem.fillAnswer = true;
                }
                if (subItem.questionBaseTypeCode == "multi") {
                  subItem.answerText = [];
                }
                if (
                  res.data.examGrade.examGradeAnswer &&
                  res.data.examGrade.examGradeAnswer.examAnswers.length > 0
                ) {
                  res.data.examGrade.examGradeAnswer.examAnswers.forEach(
                    (element) => {
                      if (element.questionUid == subItem.id) {
                        subItem.answerTime = element.answerTime;
                        if (subItem.answerTime != 0) {
                          subItem.examTime =
                            subItem.examTime - subItem.answerTime;
                        }
                        if (
                          subItem.questionBaseTypeCode == "fill" &&
                          subItem.standardAnswerCount > 0
                        ) {
                          subItem.answerText = [];
                          for (
                            let i = 0;
                            i < subItem.standardAnswerCount;
                            i++
                          ) {
                            let obj = {
                              text: element.answerText,
                            };
                            subItem.answerText.push(obj);
                          }
                        } else if (subItem.questionBaseTypeCode == "multi") {
                          if (element.answerText) {
                            subItem.answerText =
                              element.answerText.split("$contentsplit$");
                          } else {
                            subItem.answerText = [];
                          }
                          subItem.fillAnswer = true;
                        } else {
                          subItem.answerText = element.answerText;
                        }
                        this.$forceUpdate();
                      }
                    }
                  );
                }
                subItem.isStop = false;
              });
            });
            this.detail = res.data;
            this.isExistsDetail = true;
            if (this.detail.examGrade.gradeStatusCode == "pause") {
              this.pauseDialogVisible = true;
              this.isPause = true;
              this.pauseTime();
              return;
            }
            sessionStorage.setItem("baseDetail", JSON.stringify(this.detail));
            if (this.detail.examGrade.allowExamTime != 0) {
              if (sessionStorage.getItem("cutdownTime")) {
                this.cutdownTime =
                  this.detail.examGrade.allowExamTime * 1000 -
                  sessionStorage.getItem("cutdownTime") * 1000;
              } else {
                if (!this.detail.examGrade.examTime) {
                  this.cutdownTime = this.detail.examGrade.allowExamTime * 1000;
                } else {
                  this.cutdownTime =
                    this.detail.examGrade.allowExamTime * 1000 -
                    this.detail.examGrade.examTime * 1000;
                }
              }
            }
          }
        );
      }
    },
    //浏览器进入全屏
    enterScreen() {
      let element = document.getElementById("exam") || document.documentElement; //设置后就是 id==con_lf_top_div 的容器全屏
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
      this.fullscreen = true;
    },
    exitScreen() {
      if (this.fullscreen) {
        if (
          document.exitFullscreen ||
          document.documentElement.exitFullscreen
        ) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    },
    monitoringScreen(data) {
      let _this = this;
      let num = 0;
      if (sessionStorage.getItem("limitNum")) {
        num = sessionStorage.getItem("limitNum");
      } else {
        sessionStorage.setItem("limitNum", num);
      }
      let oldScreenTime = "";
      let newScreenTime = "";
      let time = null;
      let params = {
        examGradeUid: data.examGrade.id,
      };
      window.onblur = function () {
        document.title = "请继续考试";
        oldScreenTime = new Date().toLocaleString();
        params.beginTime = oldScreenTime;
        // num++;
        time = setTimeout(() => {
          num++;
          sessionStorage.setItem("limitNum", num);
        }, data.exam.switchExamPageInterval * 1000);
        console.log(num);
      };
      window.onfocus = function () {
        newScreenTime = new Date().toLocaleString();
        params.endTime = newScreenTime;
        let switchNum = 0;
        // if (num) {
        Api.screen(params)
          .then((res) => {
            console.warn("screen", res);
          })
          .catch((err) => {
            console.warn(err);
          });
        // }
        clearTimeout(time);
        document.title = "正在考试中,请勿离开";
        if (sessionStorage.getItem("limitNum")) {
          num = sessionStorage.getItem("limitNum");
        }
        if (data.exam.isSwitchExamPageCount) {
          switchNum = data.exam.switchExamPageCount;
        } else {
          switchNum = 3;
        }
        if (num < switchNum && num != 0) {
          _this.$message({
            type: "error",
            dangerouslyUseHTMLString: true,
            duration: 5000,
            message: `<strong style="font-size:22px;">已离开当前页面${num}次，离开三次将强制交卷</strong>`,
          });
        }
        if (num >= switchNum) {
          if (!this.answerDialogVisible) {
            _this.save("force");
            _this.$message({
              type: "error",
              dangerouslyUseHTMLString: true,
              duration: 5000,
              message: `<strong style="font-size:22px;">由于您离开次数过多，将强制交卷</strong>`,
            });
            // window.onblur = null;
            // window.onfocus = null;
          }
        }
      };
    },
    getAvatar(blobName) {
      axios
        .get(
          `${sessionStorage.getItem(
            "Link"
          )}api/app/file/download-file-system/${blobName}`,
          { responseType: "blob" }
        )
        .then(({ data }) => {
          let blob = new Blob([data]); // 返回的文件流数据
          let url = window.URL.createObjectURL(blob); // 将他转化为路径
          this.imgUrl = url; // 将转换出来的路径赋值给变量，其实和上一步结合就可以
        });
      this.$forceUpdate();
    },
    monitorScroll() {
      window.addEventListener("scroll", this.handleScroll);
    },
    initMouse(num) {
      console.warn("启用无操作监听");
      // 监听鼠标
      document.onmousemove = (event) => {
        let x1 = event.clientX;
        let y1 = event.clientY;
        if (this.x !== x1 || this.y !== y1) {
          this.count = 0;
          this.noOperation = false;
          this.cutdownNum = this.noOperationCount;
        }
        this.x = x1;
        this.y = y1;
      };
      // 监听键盘
      document.onkeydown = () => {
        this.count = 0;
        this.noOperation = false;
        this.cutdownNum = this.noOperationCount;
      };
      // 监听Scroll
      document.onscroll = () => {
        this.count = 0;
        this.noOperation = false;
        this.cutdownNum = this.noOperationCount;
      };
      this.setTimer(num);
    },
    clearTimer() {
      clearInterval(window.myTimer);
      window.myTimer = null;
    },
    setTimer(num) {
      this.noOperationCount = num;
      this.count = 0;
      if (!window.myTimer) {
        window.myTimer = window.setInterval(this.cookieTimeout, 1000);
      }
    },
    cookieTimeout() {
      // 判断用户noOperationCount秒无操作就自动登出
      // noOperationCount是秒数
      // let outTime = 5
      this.count++;
      if (this.count >= this.noOperationCount) {
        this.cutdownNum--;
        // if (this.count === outTime * 60) {
        //60

        setTimeout(this.logout(this.noOperationCount), 10000);
        this.noOperation = true;

        // this.$message({
        //   message: `当前已经${this.count}秒内无操作，${this.cutdownNum}秒内无操作将强制交卷，如需继续作答，请任意操作鼠标键盘...`,
        //   type: "error",
        // });
      }
    },
    logout(num) {
      if (this.count - num >= 10) {
        console.warn("强制交卷");
        this.save("force");
        this.clearTimer();
      }
    },
    handleScroll() {
      let scrollY = window.scrollY;
      let scrollObj = document.querySelector(".main-right");
      let scrollFaceObj = document.querySelector(".examFaceModal");
      let width = this.rightWidth;
      console.warn("width", width);
      if (scrollY > 250) {
        //这个358就是上面的距离
        if (scrollFaceObj) {
          scrollFaceObj.style.position = "fixed";
          scrollFaceObj.style.right = "406px";
          scrollFaceObj.style.top = "30px";
          scrollObj.style.position = "fixed";
          scrollObj.style.right = width + "px";
          scrollObj.style.top = "-80px";
        } else {
          scrollObj.style.position = "fixed";
          scrollObj.style.right = width + "px";
          scrollObj.style.top = "-80px";
        }
      } else {
        scrollObj.style.position = "absolute";
        scrollObj.style.top = "0";
        scrollObj.style.right = "0";
        if (scrollFaceObj) {
          scrollFaceObj.style.position = "static";
          scrollFaceObj.style.marginTop = "0";
        }
      }
    },
    $_initData() {
      this.requestDetail();
      window.addEventListener("setItem", () => {
        this.isConnecting = JSON.parse(sessionStorage.getItem("isConnecting"));
      });
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      if (this.userInfo.extraProperties.Avatar) {
        this.getAvatar(this.userInfo.extraProperties.Avatar);
      }
      document.oncontextmenu = function () {
        return false;
      }; // 禁止右键功能,单击右键将无任何反应
      document.onselectstart = function () {
        return false;
      }; // 禁止先择,也就是无法复制
      this.monitorScroll();
    },
  },

  mounted() {
    this.$_initData();
    let _this = this;
    this.signalr.on("ReceiveSysMsg", function (res) {
      console.log(res);
      let resData = JSON.parse(res);
      console.warn(resData);
      console.warn(resData.NotifyText);
    });
    this.signalr.on("receiveMessages", function (res) {
      //考试下线
      let resData = JSON.parse(res);
      if (_this.pauseDialogVisible && _this.isPause) {
        _this.$message.error("检测到账号在别处登录,该考试将在五秒后保存并关闭");
        let time = setTimeout(() => {
          clearTimeout(time);
          window.close();
        }, 5000);
        console.warn("receiveMessages", resData);
        return;
      } else {
        _this.save("forceSave");
        _this.$message.error("检测到账号在别处登录,该考试将在五秒后保存并关闭");
        let time = setTimeout(() => {
          clearTimeout(time);
          window.close();
        }, 5000);
      }
      console.warn("receiveMessages", resData);
    });
    this.signalr.on("ReceiveExam", function (res) {
      let resData = JSON.parse(res);
      console.warn(resData);
      if (resData && resData) {
        if (resData.CommandPara && resData.CommandName != "PauseExam") {
          _this.$message.error(resData.CommandPara);
        }
      }
      switch (resData.CommandName) {
        case "ForceToSubmit ":
          console.warn("强行提交");
          _this.updateExamStatus(resData.Id);
          let timee = setTimeout(() => {
            _this.save("force");
            clearTimeout(timee);
          }, 2000);
          break;
        case "PauseExam":
          console.warn("暂停考试");
          _this.save("forceSave");
          if (resData && resData) {
            _this.isPauseText = resData.CommandPara;
          }
          let _that = _this;
          let time = setTimeout(() => {
            console.warn("暂停timeout");
            _that.updateExamStatus(resData.Id);
            _that.pauseExam(resData.ExamGradeUid);
            _that.pauseTime();
            clearTimeout(time);
          }, 1000);
          break;
        case "EndPauseExam":
          console.warn("解除考试暂停");
          _this.updateExamStatus(resData.Id);
          _this.resumeExam(resData.ExamGradeUid);
          _this.continueExam();
          break;
        case "SendMessage":
          _this.updateExamStatus(resData.Id);
          console.warn("发送消息", resData);
          _this.$alert(resData.CommandPara, "消息通知", {
            confirmButtonText: "确定",
          });
          break;
      }
    });
  },
  destroyed() {
    this.dialogVisible = false;
    this.answerDialogVisible = false;
    window.onblur = null;
    window.onfocus = null;
    sessionStorage.setItem("limitNum", 0);
    this.exitScreen();
    sessionStorage.removeItem("cutdownTime");
    this.clearAutosave();
    document.removeEventListener("onblur", () => {
      console.log("销毁页面隐藏");
    });
    document.removeEventListener("onfocus", () => {
      console.log("销毁页面隐藏");
    });
  },
  computed: {
    rightWidth() {
      let num = (document.body.clientWidth - 1300) / 2;
      return num;
    },
    topHeight() {
      let num = document.body.clientHeight - 390;
      return num;
    },
  },
  watch: {
    detail: {
      handler(newVal, oldVal) {
        newVal.paperDetail.paperNodes.forEach((item) => {
          item.questions.forEach((subItem) => {
            if (subItem.questionBaseTypeCode == "compose") {
              subItem.childQuestions.forEach((element) => {
                if (element.questionBaseTypeCode == "fill") {
                  if (element.answerText.every(this.checkValue)) {
                    element.fillAnswer = false;
                  } else {
                    element.fillAnswer = true;
                  }
                } else if (element.answerText) {
                  if (Array.isArray(element.answerText)) {
                    if (element.answerText.length == 0) {
                      element.fillAnswer = true;
                    }
                  } else {
                    element.fillAnswer = false;
                  }
                } else {
                  element.fillAnswer = true;
                }
                console.warn("element", element);
              });
              if (subItem.childQuestions.every(this.checkAnswer)) {
                subItem.fillAnswer = false;
              }
            }
            if (
              subItem.questionBaseTypeCode != "compose" &&
              subItem.questionBaseTypeCode != "fill"
            ) {
              if (subItem.answerText && subItem.answerText != "") {
                if (Array.isArray(subItem.answerText)) {
                  if (subItem.answerText.length == 0) {
                    subItem.fillAnswer = true;
                  } else {
                    subItem.fillAnswer = false;
                  }
                } else {
                  subItem.fillAnswer = false;
                }
              } else {
                subItem.fillAnswer = true;
              }
            }
          });
        });
        if (localStorage.getItem("examList")) {
          let list = JSON.parse(localStorage.getItem("examList"));
          if (list.length == 0) {
            console.warn("无缓存");
            let arr = [];
            arr.push(newVal);
            localStorage.setItem("examList", JSON.stringify(arr));
          } else {
            let arr = list;
            let isHave = false;
            for (let i = 0; i < arr.length; i++) {
              if (arr[i].examGrade.id == newVal.examGrade.id) {
                arr[i] = newVal;
                isHave = true;
                break;
              }
            }
            if (!isHave) {
              arr.push(newVal);
            }
            localStorage.setItem("examList", JSON.stringify(arr));
          }
        } else {
          console.warn("无缓存");
          let arr = [];
          arr.push(newVal);
          localStorage.setItem("examList", JSON.stringify(arr));
        }
      },
      // 开启深度监听：只要obj中的任何一个属性发生改变，都会触发相应的代码
      deep: true,
    },
    isConnecting: {
      handler(newVal, oldVal) {
        if (newVal == true) {
          let _this = this;
          console.warn("isConnecting", "链接中断或者重连", this.isConnecting);
          if (_this.detail.exam.isRealTimeControll) {
            this.isConnectingInterval = setInterval(() => {
              let params = {
                examGradeUid: _this.detail.examGrade.id,
                examUid: _this.detail.examGrade.examUid,
                userId: _this.detail.userInfo.userUid,
              };
              _this.getOperationList(params);
            }, 60000);
          }
        } else {
          let _this = this;
          clearInterval(this.isConnectingInterval);
          if (this.isExistsDetail == true) {
            console.warn("发送下线消息,watch");
            let params = {
              ExamUid: _this.detail.examGrade.examUid,
              ExamArrangeUid: _this.detail.examGrade.examArrangeUid,
              ExamGradeUid: _this.detail.examGrade.id,
              IsOpenBook: _this.detail.exam.isOpenBook,
              ClientType: 0, // 客户端类型  0：Pc   1：移动端
              MethodName: "receiveMessages",
              SourceType: "examing",
              Message: "有新的开卷考试或者已经存在闭卷考试，当前考试即将关闭。",
            };
            this.signalr
              .invoke("ExamLimitMessage", JSON.stringify(params))
              .catch(function (err) {
                return console.error(err.toString());
              });
          }
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
$font_size_10: 10px;
$font_size_12: 12px;
$font_size_14: 14px;
$font_size_16: 16px;
.showSave {
  color: red;
  margin-right: 160px;
}
.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}
.pasue-el-dialog {
  z-index: 9999;
}
.submit-modal {
  z-index: 9999;
  position: relative;
  width: 506px !important;
  height: 308px;
  background: #ffffff;
  border-radius: 13px;
  box-shadow: 0px 2px 29px 16px rgba(0, 0, 0, 0.03);
  .icon-left {
    margin: 20px;
    margin-left: 0;
    position: absolute;
    width: 60px;
    object-fit: contain;
  }
  .title {
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: #161616;
    line-height: 33px;
    letter-spacing: 1px;
  }
  .tips {
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #999999;
    line-height: 25px;
    letter-spacing: 0px;
  }
  .score {
    font-size: 70px;
    font-weight: 500;
    text-align: center;
    color: #ff6c75;
    line-height: 94px;
  }
  .submit-btn {
    width: 204px;
    height: 37px;
    background: #1fa3ff;
    border-radius: 26px;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #ffffff;
    line-height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 26px auto;
  }
}
.btn-submit {
  width: 387px;
  height: 50px;
  cursor: pointer;
  background: #1fa3ff;
  border-radius: 26px;
  margin-top: 26px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #ffffff;
  line-height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .node-question-content {
  img {
    height: 50px !important;
    width: auto !important;
    object-fit: contain;
  }
}
.examFaceModal {
  // position: fixed;
  width: 387px;
  //   height: 350px;
  margin-bottom: 16px;
  // left: 50px;
  // top: 30px;
  // width: 300px;
  // height: 250px;
}
.exam {
  overflow-y: scroll;
  min-height: 1400px;
  .mg-rt {
    margin-right: 8px;
  }
  .el-input__inner {
    border: none !important;
  }
  .el-textarea {
    width: 70% !important;
  }
  .el-textarea__inner {
    border: none !important;
  }
  .el-card__body {
    padding: 32px 46px;
  }
  width: 100%;
  background: url("./../../assets/exam/exam-bg.jpg");
  .exam-header {
    position: relative;
    margin: 0 auto;
    margin-bottom: 24px;
    width: 1300px;
    height: 300px;
    .header-detail {
      width: 100%;
      height: 256px;
      position: relative;
      img {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }
      .paper-headr {
        left: 70px;
        top: 45px;
        position: relative;
        .exam-center {
          font-size: 14px;
          font-weight: 500;
          text-align: left;
          color: #ffffff;
          line-height: 22px;
          margin-bottom: 15px;
        }
        .paper-name {
          font-size: 32px;
          font-weight: 600;
          text-align: left;
          color: #ffffff;
          line-height: 36px;
          margin-bottom: 30px;
        }
        .paper-message {
          font-size: 12px;
          background: #f5f5f5;
          border-radius: 12px;
          display: flex;
          position: absolute;
          padding: 5px 20px;
          .label {
            font-weight: 400;
            text-align: left;
            color: #999999;
            line-height: 17px;
          }
        }
      }
    }
    .headers-nav {
      position: absolute;
      bottom: 0;
      height: 94px;
      width: 100%;
      background: linear-gradient(
        136deg,
        rgba(255, 255, 255, 0.82),
        rgba(255, 255, 255, 0.8) 100%
      );
      border-radius: 15px;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.02);
      backdrop-filter: blur(8px);
      display: flex;
      padding-left: 65px;
      align-items: center;
      justify-content: space-between;
      .user-detail {
        display: flex;
        align-items: center;
        .user-right {
          margin-left: 28px;
          .user-name {
            font-size: 18px;
            font-weight: 600;
            text-align: left;
            color: #1e2046;
            line-height: 25px;
            margin-bottom: 8px;
          }
          .user-code {
            font-size: 14px;
            font-weight: 400;
            text-align: left;
            color: #666666;
            line-height: 20px;
          }
        }
        img {
          height: 65px;
          width: 65px;
          border-radius: 50%;
        }
      }
      .nav-right {
        align-items: center;
        display: flex;
        padding-right: 32px;
        .font-select {
          display: flex;
          width: 280px;
          justify-content: space-between;
          align-items: center;
          height: 30px;
          padding-right: 8px;
          padding-left: 15px;
          margin-left: 120px;
          .select-text {
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            color: #666666;
            line-height: 15px;
            text-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.06);
          }
        }
        .operate-btn {
          align-items: center;
          display: flex;
          .exam-btn {
            align-items: center;
            display: flex;
            cursor: pointer;
          }
        }
      }
    }
  }
  .exam-main {
    position: relative;
    margin: 0 auto;
    width: 1300px;
    .example-item {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .item-content {
        border: 1px solid #e5e5e5;
        margin: 6px 0;
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
      }
      .corner {
        position: absolute;
        right: -5px;
        width: 0;
        height: 0;
        top: 1px;
        border: 5px solid #e5e5e5;
        border-bottom-color: transparent;
        border-top-color: transparent;
        border-left-color: transparent;
        transform: rotateZ(135deg);
      }
      &.finish {
        .corner {
          position: absolute;
          right: -5px;
          width: 0;
          height: 0;
          top: 1px;
          border: 5px solid #1890ff;
          border-bottom-color: transparent;
          border-top-color: transparent;
          border-left-color: transparent;
          transform: rotateZ(135deg);
        }
        .item-content {
          border: 1px solid #1890ff;
          color: #fff;
          .item-centre {
            background-color: #1890ff;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .com-item {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .item-content {
        border: 1px solid #e5e5e5;
        margin: 6px 0;
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
      }
      .corner {
        position: absolute;
        right: -5px;
        width: 0;
        height: 0;
        top: 1px;
        border: 5px solid #e5e5e5;
        border-bottom-color: transparent;
        border-top-color: transparent;
        border-left-color: transparent;
        transform: rotateZ(135deg);
      }
      &.finish {
        .corner {
          position: absolute;
          right: -5px;
          width: 0;
          height: 0;
          top: 1px;
          border: 5px solid #44d7b6;
          border-bottom-color: transparent;
          border-top-color: transparent;
          border-left-color: transparent;
          transform: rotateZ(135deg);
        }
        .item-content {
          border: 1px solid #44d7b6;
          color: #fff;
          .item-centre {
            background-color: #44d7b6;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .main-left {
      width: 890px;
      min-height: 469px;
      background: linear-gradient(
        136deg,
        rgba(255, 255, 255, 0.82),
        rgba(255, 255, 255, 0.8) 100%
      );
      border-radius: 15px;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);
      backdrop-filter: blur(8px);
      overflow: hidden;
      padding: 32px 46px;
      .sign-tips {
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #999999;
        line-height: 25px;
        display: flex;
        align-items: center;
      }
      .paper-detail {
        min-height: 360px;
        .node-head {
          margin-top: 16px;
        }
        .node-content {
          min-height: 400px;
          .node-question {
            position: relative;
            margin-bottom: 16px;
            min-height: 400px;
            padding: 16px 0 0 0;
            .content-main-foot {
              position: absolute;
              bottom: 0;
              width: 100%;
              justify-content: center;
              display: flex;
              .nextbtn {
                width: 174px;
                height: 48px;
                background: #1fa3ff;
                border-radius: 31px;
                font-size: 14px;
                font-weight: 500;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #ffffff;
              }
              .prevbtn {
                width: 174px;
                height: 48px;
                background: #dcdcdc;
                border-radius: 31px;
                font-size: 14px;
                font-weight: 500;
                text-align: center;
                color: #ffffff;
              }
            }
            p {
              margin: 0 !important;
            }
            .exam-input {
              width: 100px;
              min-width: 100px;
              border: none;
              outline: none;
              border-bottom: 1px solid #000;
            }
            .node-question-title {
              font-size: 14px;
              font-weight: 500;
              text-align: left;
              color: #333333;
              line-height: 20px;
              font-weight: 500;
              margin-bottom: 16px;
              flex-wrap: wrap;
              p {
                font-size: 14px;
                font-weight: 500;
                text-align: left;
                color: #333333;
                line-height: 20px;
              }
              .tips {
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                color: #999999;
                line-height: 25px;
                margin-left: 8px;
              }
              .node-qusetion-typeName {
                padding: 3px 8px;
                min-width: 40px;
                max-width: 80px;
                height: 22px;
                background: #f5f5f5;
                border-radius: 3px;
                font-size: 12px;
                font-weight: 400;
                color: #333333;
                margin-left: 16px;
                margin-right: 10px;
                line-height: 17px;
                &.select {
                  color: #fff !important;
                  background-color: #1890ff !important;
                  border-color: #1890ff !important;
                }
              }
            }
            .node-question-content {
              display: flex;
              padding: 4px 0 4px 16px;
              align-items: center;

              height: 100%;
              img {
                height: 50px !important;
                width: auto !important;
                object-fit: contain;
              }
            }
            .node-question-answers {
              padding: 4px 0 4px 16px;
              align-items: center;
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
      }
      &.normal {
        .sign-tips {
          font-size: $font_size_14;
        }
        .node-question-content {
          .text {
            font-size: $font_size_14 !important;
          }
        }
        .paper-detail {
          .node-head {
            font-size: $font_size_10 * 2.2;
            .tips {
              font-size: $font_size_14;
            }
          }
          .node-content {
            .node-question {
              .node-question-title {
                font-size: $font_size_14;
                p {
                  font-size: $font_size_14;
                }
                .tips {
                  font-size: $font_size_14;
                }
                .node-qusetion-typeName {
                  font-size: $font_size_12;
                }

                .node-question-answers {
                  font-size: $font_size_16;
                }
              }
            }
          }
        }
      }
      &.center {
        .node-question-content {
          .text {
            font-size: $font_size_16 !important;
          }
        }
        .el-radio__label {
          font-size: $font_size_16 !important;
        }
        .sign-tips {
          font-size: $font_size_16;
        }
        .paper-detail {
          .node-head {
            font-size: $font_size_10 * 2.4;
            .tips {
              font-size: $font_size_16;
            }
          }
          .node-content {
            .node-question {
              .node-question-title {
                font-size: $font_size_16;
                p {
                  font-size: $font_size_16;
                }
                .tips {
                  font-size: $font_size_16;
                }
                .node-qusetion-typeName {
                  font-size: $font_size_14;
                }

                .node-question-answers {
                  font-size: $font_size_14;
                }
              }
            }
          }
        }
      }
      &.big {
        .node-question-content {
          .text {
            font-size: $font_size_10 * 1.8 !important;
          }
        }
        .el-radio__label {
          font-size: $font_size_10 * 1.8 !important;
        }
        .sign-tips {
          font-size: $font_size_10 * 1.8;
        }
        .paper-detail {
          .node-head {
            font-size: $font_size_10 * 2.6;
            .tips {
              font-size: $font_size_10 * 1.8;
            }
          }
          .node-content {
            .node-question {
              .node-question-title {
                font-size: $font_size_10 * 1.8;
                p {
                  font-size: $font_size_10 * 1.8;
                }
                .tips {
                  font-size: $font_size_10 * 1.8;
                }
                .node-qusetion-typeName {
                  font-size: $font_size_16;
                }

                .node-question-answers {
                  font-size: $font_size_16;
                }
              }
            }
          }
        }
      }
      &.oversized {
        .node-question-content {
          .text {
            font-size: $font_size_10 * 2 !important;
          }
        }
        .el-radio__label {
          font-size: $font_size_10 * 2 !important;
        }
        .sign-tips {
          font-size: $font_size_10 * 2;
        }
        .paper-detail {
          .node-head {
            font-size: $font_size_10 * 2.8;
            .tips {
              font-size: $font_size_10 * 2;
            }
          }
          .node-content {
            .node-question {
              .node-question-title {
                font-size: $font_size_10 * 2;
                p {
                  font-size: $font_size_10 * 2;
                }
                .tips {
                  font-size: $font_size_10 * 2;
                }
                .node-qusetion-typeName {
                  font-size: $font_size_10 * 1.8;
                }

                .node-question-answers {
                  font-size: $font_size_10 * 1.8;
                }
              }
            }
          }
        }
      }
    }
    .main-right {
      width: 386px;
      position: absolute;
      right: 0;
      .time-remaining {
        height: 77px;
        justify-content: space-between;
        display: flex;
        background-color: #f2f2f2;
        border-radius: 15px;
        align-items: center;
        padding: 0 16px;
        margin-bottom: 24px;
        background: linear-gradient(
          136deg,
          rgba(255, 255, 255, 0.82),
          rgba(255, 255, 255, 0.8) 100%
        );
        border-radius: 15px;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.02);
        backdrop-filter: blur(8px);
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        color: #000000;
        .block {
          font-size: 30px;
          font-weight: 500;
          text-align: left;
          color: #1fa3ff;
          line-height: 42px;
        }
      }
      .answers-card {
        border-radius: 15px;
        width: 386px;
        overflow: hidden;
        background: linear-gradient(
          136deg,
          rgba(255, 255, 255, 0.82),
          rgba(255, 255, 255, 0.8) 100%
        );
        border-radius: 15px;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.02);
        backdrop-filter: blur(8px);
        padding-bottom: 8px;
        font-size: 12px;
        font-weight: 500;
        color: #9b9b9b;
        line-height: 17px;

        .card-head {
          display: flex;
          align-items: center;
          padding: 0 16px;
          color: #fff;
          height: 52px;
          background: #102345;
          border-radius: 15px 15px 0px 0px;
        }
        .noAnswer {
          width: 6px;
          height: 6px;
          border: 1px solid #9b9b9b;
          border-radius: 4px;
        }
        .isAnswer {
          width: 6px;
          height: 6px;
          background: #1fa3ff;
          border-radius: 4px;
        }
        .card-title {
          height: 42px;
          border-bottom: 1px solid #e1e1e1;
          .title-item {
            display: inline-flex;
            align-items: center;
            margin-right: 18px;
          }
        }
        .question-modal {
          padding: 8px 0;
          width: 100%;
          .modal-title {
            display: flex;
            margin-bottom: 6px;
            align-items: center;
            width: 100%;
            height: 30px;
            padding: 0 18px;
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            color: #000000;
            line-height: 20px;
          }
          .tips {
            margin-left: 10px;
            font-size: 12px;
            font-weight: 400;
            text-align: right;
            color: #9b9b9b;
            line-height: 17px;
          }
          .modal-content {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            padding: 0 13px;

            .compose-item {
              color: #000;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 10%;
              // max-width: 38px;
              min-width: 36px;
              position: relative;
              .corner {
                position: absolute;
                right: -5px;
                width: 0px;
                height: 0px;
                top: -5px;
                border: 4px solid #797979;
                border-bottom-color: transparent;
                border-top-color: transparent;
                border-left-color: transparent;
                transform: rotateZ(135deg);
              }
              .item-content {
                position: relative;
                border: 1px solid #e5e5e5;
                margin: 6px 0;
                padding: 3px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                box-sizing: border-box;
                .item-centre {
                  width: 18px;
                  height: 18px;
                  font-size: 12px;
                  font-weight: 400;
                  text-align: center;
                  color: #4a4a4a;
                  line-height: 17px;
                }
              }
              &.finish {
                .corner {
                  position: absolute;
                  right: -5px;
                  width: 0px;
                  height: 0px;
                  top: -5px;
                  border: 4px solid #44d7b6;
                  border-bottom-color: transparent;
                  border-top-color: transparent;
                  border-left-color: transparent;
                  transform: rotateZ(135deg);
                }
                .item-content {
                  position: relative;
                  border: 1px solid #e5e5e5;
                  color: #fff;
                  .item-centre {
                    color: #fff;

                    background: #44d7b6;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
            .question-item {
              color: #000;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 10%;
              // max-width: 38px;
              min-width: 36px;
              position: relative;
              .corner {
                position: absolute;
                right: -5px;
                width: 0px;
                height: 0px;
                top: -5px;
                border: 4px solid #797979;
                border-bottom-color: transparent;
                border-top-color: transparent;
                border-left-color: transparent;
                transform: rotateZ(135deg);
              }
              .item-content {
                position: relative;
                border: 1px solid #e5e5e5;
                margin: 6px 0;
                padding: 3px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                box-sizing: border-box;
                .item-centre {
                  width: 18px;
                  height: 18px;
                  font-size: 12px;
                  font-weight: 400;
                  text-align: center;
                  color: #4a4a4a;
                  line-height: 17px;
                }
              }
              &.finish {
                .corner {
                  position: absolute;
                  right: -5px;
                  width: 0px;
                  height: 0px;
                  top: -5px;
                  border: 4px solid #1fa3ff;
                  border-bottom-color: transparent;
                  border-top-color: transparent;
                  border-left-color: transparent;
                  transform: rotateZ(135deg);
                }
                .item-content {
                  position: relative;
                  border: 1px solid #e5e5e5;
                  color: #fff;
                  .item-centre {
                    color: #fff;

                    background: #1fa3ff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
    .paper-headr {
      border-bottom: 1px solid #000;
      .paper-name {
        font-size: 24px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .paper-message {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>