<template>
  <!-- <div class="publish"> -->
  <el-dialog
    width="480px"
    :visible.sync="showFace"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <div v-if="!isFace">
      <div v-if="!showRule">
        <div>
          <el-row class="exam-name">{{ detail.examName }}</el-row>
          <el-row class="row">
            考试时间： {{ detail.beginTimeFormat }} 至
            {{ detail.endTimeFormat }}
          </el-row>
          <el-row class="row">
            考试时长：
            {{
              detail.examTime ? `${detail.examTime / 60}分钟` : "不限"
            }}</el-row
          >
          <el-row class="row">
            答题方式：
            {{
              detail.examDoModeCode == "paper" ? `整卷模式` : "逐题模式"
            }}</el-row
          >
          <el-row class="row">
            参加次数：<span style="color: #009dff"
              >{{ detail.attendTimes }} </span
            >/ {{ detail.maxExamNum ? detail.maxExamNum : "无限" }}</el-row
          >
        </div>
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 16px;
          "
        >
          <div class="face-modal" v-if="detail.isOpenFaceAuthentication">
            <div class="face-modal-top" style="display: flex">
              <div>
                <div class="title">本场考试需要进行人脸识别验证</div>
                <div class="text">1. 请确认由本人亲自操作；</div>
                <div class="text">2. 请将脸部置于提示框内；</div>
                <div class="text">3. 正对摄像头；保持光线充足</div>
              </div>
              <div>
                <img src="../../assets/home/face-recognition.png" alt="" />
              </div>
            </div>
            <div class="tips">
              <img
                style="margin-right: 20px"
                src="../../assets/home/bugle.png"
                alt=""
              />
              <el-checkbox v-model="isCheck"></el-checkbox>
              <span @click="toRule" class="rule-tips">
                我已详细阅读考前须知细则
              </span>
            </div>
            <div>
              <el-button
                style="
                  width: 174px;
                  background-color: #dcdcdc;
                  border: none;
                  height: 46px;
                  border-radius: 25px;
                "
                type="info"
                @click.stop="handleClose"
                >取消</el-button
              >
              <el-button
                style="width: 174px; height: 46px; border-radius: 25px"
                type="primary"
                @click.stop="openFaceRecognition"
                >开始认证</el-button
              >
            </div>
          </div>
          <div v-else style="width: 100%">
            <div class="tips" v-if="detail.isExamBeforeNotice">
              <img
                style="margin-right: 25px"
                src="../../assets/home/bugle.png"
                alt=""
              />
              <el-checkbox v-model="isCheck"></el-checkbox>
              <span @click="toRule" class="rule-tips">
                我已详细阅读考前须知细则
              </span>
            </div>
            <div style="display: flex; justify-content: center">
              <el-button
                style="width: 174px; height: 46px; border-radius: 25px"
                type="primary"
                @click.stop="checkRule"
                >开始考试</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div v-else class="exam-rule">
        <div class="rule-title">
          <div class="rule-button" @click="back"><</div>
          <span> 考试须知 </span>
        </div>
        <div
          v-html="detail.examBeforeNotice"
          style="line-height: 24px; text-align: justify"
        ></div>
      </div>
    </div>
    <div v-show="isFace">
      <div style="display: flex; align-items: center; flex-direction: column">
        <div
          style="
            text-align: center;
            margin-bottom: 16px;
            font-size: 24px;
            font-weight: 700;
            color: #333333;
          "
        >
          人脸识别验证
        </div>
        <div>
          <video style="margin: 0 auto" ref="video" v-if="isVideo"></video>
          <img v-else :src="imgSrc" alt="" />
          <canvas
            style="display: none"
            width="400"
            height="230"
            id="canvasCamera"
          ></canvas>
        </div>
        <!-- <el-button type="primary" @click="OpenCamera">打开摄像头</el-button>
                <el-button type="primary" @click="CloseCamera">关闭摄像头</el-button>
                <el-button type="primary" @click="setImage">拍照</el-button> -->
      </div>
      <div
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 16px;
        "
      >
        <el-tag v-if="!isDiscern && iSCamera">正在识别...</el-tag>
        <el-button
          v-if="!isOk && isDiscern"
          type="primary"
          @click.stop="OpenCamera"
          >重新识别</el-button
        >
        <!-- <el-button v-if="!isOk && isDiscern" type="primary" @click.stop="setImage">重新识别</el-button> -->
        <el-button v-if="isOk" type="primary" @click.stop="goExamDetail(detail)"
          >即将进入考试({{ countTime }})</el-button
        >
        <!-- <el-button v-if="isOk" type="primary" @click.stop="goExam(detail)">开始考试</el-button> -->
        <!-- <el-button type="primary" @click.stop="CloseCamera">关闭摄像头</el-button> -->
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Api from "@/api/examList";
export default {
  props: {
    showFace: {
      required: true,
      type: Boolean,
      default: false,
    },
    goExam: {
      required: true,
      type: Function,
      default: false,
    },
    detail: {
      require: true,
      type: Object,
      default: {
        examName: "",
        beginTimeFormat: "",
        endTimeFormat: "",
        examTime: "",
        maxExamNum: "",
        openCameraInvigilateInterval: 0.5,
        IsOpenFaceAuthentication: false,
        attendTimes: 0,
      },
    },
  },
  data() {
    return {
      countTime: 3,
      iSCamera: true,
      isVideo: true,
      isFace: false,
      isOk: false,
      isCheck: false,
      mediaStreamTrack: {},
      video_stream: "", // 视频stream
      imgSrc: "", // 拍照图片
      canvas: null,
      context: null,
      isDiscern: false,
      showRule: false,
      countDownTime: null,
    };
  },
  created() {
    console.warn(this.detail);
    if (this.detail.isExamBeforeNotice == false) {
      this.isCheck = true;
    }
    (this.isOk = false), (this.isDiscern = false), (this.isFace = false);
  },
  destroyed() {
    this.CloseCamera();
    (this.isOk = false), (this.isDiscern = false), (this.isFace = false);
  },
  methods: {
    goExamDetail(detail) {
      this.countTime = 0;
      this.CloseCamera();
      clearInterval(this.countDownTime);
      this.goExam(detail);
    },
    toRule() {
      this.showRule = true;
      this.$forceUpdate();
    },
    back() {
      this.showRule = false;
      this.isCheck = true;
      this.$forceUpdate();
    },
    checkRule() {
      if (this.isCheck) {
        this.goExam(this.detail);
      } else {
        this.$message.error("请阅读并同意考前须知");
      }
    },
    openFaceRecognition() {
      if (this.isCheck) {
        this.isFace = true;
        this.OpenCamera();
      } else {
        this.$message.error("请阅读并同意考前须知");
      }
    },
    handleClose() {
      this.$emit("closepop"); //通知父组件改变。
      this.CloseCamera();
    },
    // 调用打开摄像头功能
    getCamera() {
      // 获取 canvas 画布

      this.canvas = document.getElementById("canvasCamera");
      this.context = this.canvas.getContext("2d");
      // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      // 正常支持版本
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({
            video: true,
          })
          .then((stream) => {
            // 摄像头开启成功
            console.warn("摄像头开启成功");
            this.mediaStreamTrack =
              typeof stream.stop === "function"
                ? stream
                : stream.getTracks()[0];
            this.video_stream = stream;
            this.$refs.video.srcObject = stream;
            this.$refs.video.play();

            let take = setTimeout(() => {
              this.setImage();
              clearTimeout(take);
            }, 3000);
          })
          .catch((err) => {
            console.log(err);
            this.isDiscern = true;
            this.iSCamera = false;

            // this.$message.error('摄像头开启失败，请检查摄像头是否可用！');
            this.$message({
              type: "error",
              // dangerouslyUseHTMLString: true,
              duration: 5000,
              message: `摄像头开启失败，请检查摄像头是否可用！`,
            });
          });
      } else {
        this.isDiscern = true;
        this.iSCamera = false;
        // this.$message.error('摄像头开启失败，请检查摄像头是否可用！');
        this.$message({
          type: "error",
          // dangerouslyUseHTMLString: true,
          duration: 5000,
          message: `摄像头开启失败，请检查摄像头是否可用！`,
        });
      }
    },
    // 拍照 绘制图片
    setImage() {
      console.log("拍照");
      this.isVideo = true;

      // 点击canvas画图
      // 获取图片base64链接
      this.context.drawImage(this.$refs.video, 0, 0, 400, 230);
      let image = this.canvas.toDataURL("image/png");
      this.imgSrc = image;
      if (this.imgSrc) {
        this.checkFace(this.imgSrc);
      } else {
        let haveImg = setInterval(() => {
          if (this.imgSrc) {
            this.checkFace(this.imgSrc);
            clearInterval(haveImg);
          }
        }, 300000);
      }
      this.$emit("refreshDataList", this.imgSrc);
    },

    checkFace(imgSrc) {
      let params = {};
      params.image = imgSrc;
      params.batchId = this.detail.examUid;
      params.relationId = this.detail.examGradeUid;
      params.sourceType = "exam";
      params.callConfigList = [0, 3];
      Api.verifyFaceAll(params)
        .then((res) => {
          if (res.data.status === 1) {
            if (res.data.businessData.isSuccess) {
              this.isDiscern = true;
              this.isOk = true;
              this.detail.verificationKey =
                res.data.businessData.verificationKey;
              console.warn(this.detail);
              this.$message.success("识别通过,即将考试");
              this.countDownTime = setInterval(() => {
                this.countTime--;
              }, 1000);
              let time = setTimeout(() => {
                this.CloseCamera();
                this.goExam(this.detail);
                clearTimeout(time);
                clearInterval(this.countDownTime);
              }, 3000);
              // this.$message.success('识别通过,请点击开始考试');
            } else {
              this.isDiscern = true;
              this.isVideo = false;
              this.CloseCamera();
              let _this = this;
              this.$confirm("识别失败,是否重新识别?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  _this.isDiscern = true;
                  _this.OpenCamera();
                })
                .catch(() => {});
              this.$message.error("识别错误,请重新识别");
            }
          } else {
            this.isDiscern = true;

            this.$message.error(res.data.errorMessage);
          }
          console.warn(res);
        })
        .catch((err) => {
          this.CloseCamera();
          this.isDiscern = true;
          this.isVideo = false;
          console.warn(err);
        });
    },
    // 打开摄像头
    OpenCamera() {
      console.log("打开摄像头");
      this.isVideo = true;
      this.isDiscern = false;
      this.getCamera();
    },
    // 关闭摄像头
    CloseCamera() {
      this.MediaStreamTrack && this.MediaStreamTrack.stop();
      this.$refs.video.srcObject.getTracks()[0].stop();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  border-radius: 18px;
}

.tips {
  font-size: 14px;
  // height: 90px;
  font-weight: 300;
  text-align: center;
  color: #1fa3ff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
  position: relative;
  .rule-tips {
    margin-left: 8px;
  }
}
.face-modal {
  width: 480px;
  border-top: 1px solid #e6e6e6;
  padding-left: 30px;
  padding-top: 28px;
  .face-modal-top {
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      color: #1fa3ff;
    }
    .text {
      font-size: 16px;
      margin-top: 8px;
      font-weight: 400;
      text-align: left;
      color: #999999;
    }
  }
}
.exam-rule {
  .rule-title {
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    color: #333333;
    position: relative;
    margin-bottom: 30px;
    .rule-button {
      width: 29px;
      height: 29px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f5f5f5;
      border-radius: 2px;
      color: #a1a1a1;
      position: absolute;
    }
  }
  .rule-text {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #999999;
    line-height: 26px;
  }
  padding: 0 30px;
}
video {
  width: 400px;
  height: 230px;
}
canvas {
  // width: 100%;
  // height: 300px;
  width: 400px;
  height: 230px;
}
.customClass {
  width: 510px;
}
.exam-name {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #333333;
  margin-bottom: 22px;
}
.row {
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  padding-left: 30px;
  color: #999999;
  // line-height: 32px;
}
</style>