/*
 * @Author: your name
 * @Date: 2021-11-03 14:51:36
 * @LastEditTime: 2021-11-22 18:16:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web-pc\src\config\index.js
 */
export const _isDev = process.env.NODE_ENV === 'development'

const testLoginPrefix = 'http://43.136.53.156:53362'
const prodLoginPrefix = '/connect'
const testServerPrefix = 'http://43.136.53.156:6216'
const prodServerPrefix = '/api'
const testSignalrUrlPrefix = 'http://43.136.53.156:55389'
const prodSignalrUrlPrefix = '/signalr'
export const serverPrefix = _isDev ? testServerPrefix : prodServerPrefix
export const loginPrefix = _isDev ? testLoginPrefix : prodLoginPrefix
export const SignalrUrlPrefix = _isDev ? testSignalrUrlPrefix : prodSignalrUrlPrefix

