<!--
 * @Author: your name
 * @Date: 2021-10-29 14:18:29
 * @LastEditTime: 2022-04-27 17:24:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web-exam\src\components\nav.vue
-->
<template>
  <div class="list-nav">
    <div class="bulr"></div>
    <div class="bulr-top">
      <div class="nav-left">
        <img src="../assets/list/logo-gz.png" />
        <!--    -->
      </div>
      <div class="nav-center">
        <div class="nav-menu">
          <el-dropdown
            placement="bottom-start"
            v-for="(item, index) in navList"
            @click="pullDown(item)"
            :key="index"
          >
            <span
              class="menu-item"
              :class="[path == item.link && 'selectMenu']"
              @click="goPage(item)"
            >
              {{ item.name }}
            </span>
            <el-dropdown-menu v-show="false" slot="dropdown">
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="nav-right">
          <div class="search-item" @click="showSearchModal">
            <img src="../assets/list/search-btn.png" alt="" />
            搜索
          </div>
          <div style="display: flex; align-items: center">
            <img
              class="user-portrait"
              v-if="imgUrl"
              @mouseover="mouseEnter()"
              @mouseleave="mouseImgOut()"
              :src="imgUrl"
              alt=""
            />
            <img
              v-else-if="userInfo.extraProperties.Sex == 'female'"
              class="user-portrait"
              src="../assets/female.png"
              @mouseover="mouseEnter()"
              @mouseleave="mouseImgOut()"
              alt=""
            />
            <img
              v-else
              class="user-portrait"
              src="../assets/male.png"
              @mouseover="mouseEnter()"
              @mouseleave="mouseImgOut()"
              alt=""
            />
          </div>
          <div
            class="user-modal"
            :class="isHover && 'isShow'"
            @mouseover="mouseEnterModal()"
            @mouseleave="mouseOut()"
          >
            <div class="user-info" v-if="userInfo.extraProperties">
              <img class="head-img" v-if="imgUrl" :src="imgUrl" alt="" />
              <img
                v-else-if="userInfo.extraProperties.Sex == 'female'"
                class="head-img"
                src="../assets/female.png"
                alt=""
              />
              <img v-else class="head-img" src="../assets/male.png" alt="" />
              <div>
                <div class="user-name">{{ userInfo.userName }}</div>
                <div class="user-code">姓名：{{ userInfo.name }}</div>
              </div>
            </div>
            <div class="modal-center">
              <div class="modal-item" @click="goLink('/exam/record')">
                <img class="mg-bt" src="./../assets/home/record.png" alt="" />
                <span>我的档案</span>
              </div>
              <div class="modal-item" v-if="auth" @click="goBackstage">
                <img
                  class="mg-bt"
                  src="./../assets/home/backstage.png"
                  alt=""
                />
                <span>后台管理</span>
              </div>
            </div>
            <div class="modal-bottom">
              <div
                @click="logout"
                style="display: flex; align-items: center; cursor: pointer"
              >
                <img
                  class="mg-bt"
                  style="width: 16px; height: 16px"
                  src="../assets/logout.png"
                  alt=""
                />
                <div>安全退出</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-bottom" :class="isShowSearch && 'show-nav-bottom'">
      <div>
        <div class="close-btn" @click="isShowSearch = false">
          <img src="../assets/home/close.png" alt="" />
        </div>
        <div class="search-input-modal">
          <el-input
            placeholder="输入关键词进行搜索"
            v-model="searchText"
            type="text"
            class="search-input"
          />
          <el-button type="primary" class="search-btn" @click="goDetail">
            <div class="btn-text">
              <img src="../assets/list/search-btn.png" alt="" />
              <span>搜索</span>
            </div>
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/home";
import Cookie from "js-cookie";
import axios from "axios";
import { getPermissions } from "../plugin/axios";
import userApi from "@/api/home.js";

export default {
  props: {
    newAvatar: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      clearShow: "",
      navList: [
        // { name: "首页", link: "/home" },
        //  { name: '学习中心' },
        { name: "考试中心", link: "/exam/list" },
        { name: "资讯中心", link: "/newsList/list" },
        //    { name: '调查问卷' },
        //    { name: '职业发展' },
        // { name: '审批中心' }
      ],
      searchText: "",
      isHover: false,
      isShowSearch: false,
      userInfo: {
        extraProperties: {},
        userName: "",
      },
      Link: "",
      auth: "",
      path: "",
      imgUrl: "",
    };
  },
  methods: {
    showSearchModal() {
      this.isShowSearch = true;
    },
    goDetail() {
      switch (this.$route.path) {
        case "/home":
          this.$router.push(`/exam/list?name=${this.searchText}`);
          break;
        case "/exam/list":
          this.$router.push(`/exam/list?name=${this.searchText}`);
          break;
        case "/newsList/list":
          this.$router.push(`/newsList/list?name=${this.searchText}`);
          break;
      }
    },
    goBackstage() {
      let token,
        towhere,
        requestUrl = "";
      towhere = 0;
      token = sessionStorage.getItem("token");

      requestUrl = this.Link.match(
        /https?:\/\/(.+?\.\w+\.\w+(:\d+)?)(?=\/)/
      )[1];
      Api.getNewToken(token, towhere, requestUrl)
        .then((res) => {
          Cookie.set("Admin-Token", res.data.access_token);
          window.location.href = this.Link;
        })
        .catch((err) => {
          console.warn(err);
        });
      // 'http://43.136.53.156:8080/#/exam/question';
      // window.open('')
    },
    goLink(item) {
      this.$router.push(item);
    },
    mouseEnterModal() {
      clearTimeout(this.clearShow);
      this.isHover = true;
    },
    mouseEnter() {
      this.isHover = true;
      // this.clearShow = setTimeout(() => {
      //     this.isHover = false;
      // }, 3000);
      this.$forceUpdate();
    },
    mouseImgOut() {
      this.clearShow = setTimeout(() => {
        this.isHover = false;
      }, 3000);
      this.$forceUpdate();
    },
    mouseOut() {
      // this.clearShow = setTimeout(() => {
      this.isHover = false;
      // }, 3000);

      this.$forceUpdate();
    },
    logout() {
      Api.userUpdate()
        .then((res) => {
          console.warn(res);
          this.clearAllAndLogout();
        })
        .catch((err) => {
          console.warn(err);
          this.clearAllAndLogout();
        });
    },
    clearAllAndLogout() {
      sessionStorage.clear();
      let time = setTimeout(() => {
        this.isHover = false;
        clearTimeout(time);
      }, 1000);
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("baseDetail");
      if (this.$route.query.token) {
        this.$route.query.token = "";
        this.$router.push("/login");
      } else {
        this.$router.push("/login");
      }
      let logoutTime = setTimeout(() => {
        clearTimeout(logoutTime);
      }, 1000);
    },
    goPage(item) {
      if (item.link) {
        if (this.$route.path != item.link) {
          this.$router.push(item.link);
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        } else if (this.$route.path == item.link) {
          this.$router.push(item.link);
          console.warn(this.$route);
          location.reload();
        }
      }
    },
    pullDown(item) {
      console.warn(item);
    },
    getAvatar(blobName) {
      if (blobName) {
        let url = this.Link.split("#")[0];
        sessionStorage.setItem("Link", url);
        axios
          .get(`api/app/file/download-file-system/${blobName}`, {
            responseType: "blob",
          })
          .then(({ data }) => {
            let blob = new Blob([data]); // 返回的文件流数据
            let url = window.URL.createObjectURL(blob); // 将他转化为路径
            this.imgUrl = url; // 将转换出来的路径赋值给变量，其实和上一步结合就可以
            this.blobToBase64(blob).then((res) => {
              // 转化后的base64
              let base64Url = res.split(";base64");
              sessionStorage.setItem(
                "Avatar",
                "data:image/png;base64" + base64Url[base64Url.length - 1]
              );
            });
          });
        this.$forceUpdate();
      }
    },
    blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          resolve(e.target.result);
        };
        // readAsDataURL
        fileReader.readAsDataURL(blob);
        fileReader.onerror = () => {
          reject(new Error("blobToBase64 error"));
        };
      });
    },
    getUserInfo() {
      getPermissions("/api/abp/application-configuration")
        .then((res) => {
          let arr = Object.keys(res.auth.grantedPolicies);
          if (arr.length == 0) {
            sessionStorage.setItem("auth", false);
            this.auth = false;
            this.$forceUpdate();
          } else {
            sessionStorage.setItem("auth", true);
            this.auth = true;
            this.$forceUpdate();
          }
          if (res.currentUser.id) {
            sessionStorage.setItem("userId", res.currentUser.id);
            userApi
              .getUserInfo(res.currentUser.id)
              .then((result) => {
                sessionStorage.setItem("userInfo", JSON.stringify(result.data));
                this.userInfo = result.data;
                if (
                  this.userInfo &&
                  this.userInfo.extraProperties &&
                  this.userInfo.extraProperties.Avatar
                ) {
                  this.getAvatar(this.userInfo.extraProperties.Avatar);
                }
                if (!localStorage.getItem("examList")) {
                  localStorage.setItem("examList", []);
                }
              })
              .catch((err) => {
                console.warn(err);
              });
          } else {
            this.logout();
          }
        })
        .catch((err) => {
          reject(error);
        });
    },
  },
  created() {
    this.path = this.$route.path;
    if (sessionStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      if (
        this.userInfo &&
        this.userInfo.extraProperties &&
        this.userInfo.extraProperties.Avatar
      ) {
        this.getAvatar(this.userInfo.extraProperties.Avatar);
      }
    } else {
      this.getUserInfo();
    }

    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    this.$forceUpdate();
    Api.getCopyright()
      .then((res) => {
        res.data.forEach((item) => {
          if (item.name == "Abp.Identity.User.AdminUrl") {
            this.Link = item.value;
          }
        });
      })
      .catch((err) => {
        console.warn(err);
      });
  },
  watch: {
    newAvatar: {
      handler(newVal, oldVal) {
        let name = newVal;
        if (newVal != "") {
          this.getUserInfo();
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  height: 100% !important;
  border: 1px solid #1fa3ff;
  padding-left: 32px;
}
.modal-center {
  display: flex;
  flex-wrap: wrap;
  .mg-bt {
    margin-bottom: 12px;
  }
  .modal-item {
    cursor: pointer;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    width: 33.3%;
    align-items: center;
    justify-content: center;
    img {
      width: 25px;
      height: 25px;
    }
  }
}
.el-dropdown-menu {
  border-radius: 10px;
}
.el-dropdown-menu__item {
  font-size: 16px;
  // font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  min-width: 120px;
  line-height: 42px;
}

.list-nav {
  background: rgba(255, 255, 255, 0.3);
  z-index: 99;
  height: 86px;
  display: flex;
  position: absolute;
  // top: 0;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.04);

  .bulr {
    background: rgba(255, 255, 255, 0.3);
    position: absolute;
    height: 100%;
    width: 100%;
    filter: blur(20px);
    top: 0;
  }
  .bulr-top {
    display: flex;
    position: absolute;
    justify-content: space-between;
    padding: 0 120px;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
  }
  .nav-left {
    color: #1e81df;
    font-size: 32px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    img {
      // width: 162px;
      height: 30px;
    }
    // flex: 1;
  }
  .nav-center {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    // flex: 7;
    .nav-menu {
      display: flex;
      width: 100%;
      height: 86px;
      align-items: center;
      justify-content: flex-end;
      .menu-item {
        cursor: pointer;

        // padding: 30px 45px;
        font-size: 16px;
        // font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #1e2044;
        display: flex;
        justify-content: center;
        margin-right: 45px;
        align-items: center;
        .pull-down {
          width: 8px;
          height: 4px;
          margin-left: 10px;
        }
      }
      .selectMenu {
        color: #1fa3ff !important;
        position: relative;
        &::before {
          display: block;
          content: "";
          width: 120%;
          height: 3px;
          background: #1fa3ff;
          position: absolute;
          bottom: -12px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      // background-color:pink;
    }
  }
  .nav-right {
    position: relative;
    display: flex;
    .search-item {
      cursor: pointer;
      width: 104px;
      height: 46px;
      background: #1fa3ff;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      // font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: #ffffff;
      margin-right: 20px;
      img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }
    }
    .user-portrait {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      overflow: hidden;
    }
    .user-modal {
      display: none;
      height: auto;
      position: absolute;
      top: 55px;
      right: 0;
      width: 324px;
      // height: 334px;
      background: #ffffff;
      border-radius: 18px;
      overflow: hidden;
      box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.04);
      &.isShow {
        display: block !important;
      }
      .user-info {
        height: 107px;
        display: flex;
        align-items: center;
        background-color: #f8f8f8;
        padding: 20px 24px;

        .head-img {
          width: 70px;
          height: 70px;
          margin-right: 16px;
          border-radius: 50%;
          overflow: hidden;
        }
        .user-name {
          font-size: 20px;
          // font-family: PingFangSC, PingFangSC-Semibold;
          font-weight: 600;
          text-align: left;
          color: #000000;
          line-height: 28px;
        }
        .user-code {
          font-size: 14px;
          // font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          color: #999999;
          line-height: 28px;
        }
      }
      .modal-bottom {
        z-index: 9;
        height: 68px;
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        // font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        color: #333333;
        line-height: 28px;
      }
    }
  }
  .show-nav-bottom {
    display: flex !important;
  }
  .nav-bottom {
    height: 370px;
    display: none;
    background-color: #fff;
    width: 100%;
    position: absolute;
    bottom: -368px;
    // display: flex;
    left: 0;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 60px;
    .close-btn {
      cursor: pointer;
      width: 100%;
      height: 32px;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 16px;
    }
    .search-input-modal {
      display: flex;
      width: 650px;
      height: 70px;
      position: relative;
      background: #ffffff;
      // border-radius: 15px;
      overflow: hidden;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);
      .search-btn {
        width: 170px;
        height: 100%;
        z-index: 99;
        position: absolute;
        right: 0;
        .btn-text {
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            margin-left: 16px;
            height: 28px;
            font-size: 20px;
            // font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            text-align: left;
            color: #ffffff;
            line-height: 28px;
          }
        }
      }
    }
  }
}
</style>