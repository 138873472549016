/*
 * @Author: your name
 * @Date: 2021-09-22 10:02:54
 * @LastEditTime: 2022-03-22 16:45:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web-admin\src\api\settings.js
 */

import { axios } from './../plugin/axios'
export default {
    getList(params) {
        return axios.post(`/api/examservice/exam/list/user`, params)
    },
    getNoSubmitList(params) {
        return axios.post(`/api/examservice/exam/list/user/no-submit`, params)
    },
    getExamUserInfo() {
        return axios.post(`/api/examservice/exam/exam-statistics/me`, {})
    },
    checkExam(examUid, examArrangeUid, params) {
        return axios.post(`/api/examservice/exam/${examUid}/arranges/${examArrangeUid}/join/check`, params)
    },
    getNewsList(params) {
        return axios.post(`/api/base/surpriseNews/web/list?SkipCount=0&MaxResultCount=4`, params)
    },
    goSubmit(examUid, examGradeUid, params) {
        return axios.post(`/api/examservice/exam/${examUid}/grades/${examGradeUid}/save-user-answer-resubmit`, params)
    },
    getRecordList(params) {
        return axios.post(`/api/examservice/exam/list/users/grades`, params)
    },
    verifyFaceAll(params) {
        return axios.post(`/api/business/face-recognition/verify/call`, params)
    }

}