/*
 * @Author: your name
 * @Date: 2021-11-16 18:35:08
 * @LastEditTime: 2022-03-11 17:37:38
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \web-pc\src\plugin\connection.js
 */
import Vue from 'vue'
import { SignalrUrlPrefix } from '../config/index'

import router from './router';

function init() {
    const signalR = require("@microsoft/signalr/dist/browser/signalr.js");
    // 创建连接
    // const sign = new SignalR.HubsignalrBuilder()
    //     .withUrl("http://192.168.2.22:9003/chatHub", {})
    //     .configureLogging(SignalR.LogLevel.Error)  // 日志等级
    //     .build();
    let userToken = ''
    if (sessionStorage.getItem('token')) {
        userToken = sessionStorage.getItem('token')
    }
    let signalrUrl = `${SignalrUrlPrefix}/signalr/chatHub`;
    const connection = new signalR.HubConnectionBuilder()
        .withUrl(signalrUrl, { accessTokenFactory: () => userToken })
        .configureLogging(signalR.LogLevel.Information)
        .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: retryContext => {
                if (retryContext.elapsedMilliseconds < 1800000) {
                    return Math.random() * 10000;
                } else {
                    return null;
                }
            }
        }).build();
    connection.on('OfflineNotify', function (res) {
        //考试下线
        let resData = JSON.parse(res);
        Vue.prototype.$message.error(resData.Content)
        let time = setTimeout(() => {
            clearTimeout(time)
            sessionStorage.clear();
            router.push('/login')
            window.location.reload();
        }, 2000);
        sessionStorage.clear();
        let encodedMsg = "消息通知：" + resData
        console.warn(encodedMsg);
        // window.location.reload();
    });
    //重新连接进行中
    connection.onreconnecting(error => {
        console.assert(connection.state === signalR.HubConnectionState.Reconnecting);
        Vue.prototype.resetSetItem('isConnecting', true);
        console.warn('重连中..........', signalR);
    });
    //重新连接后
    connection.onreconnected(connectionId => {
        console.assert(connection.state === signalR.HubConnectionState.Connected);
        console.warn('重连成功', signalR);
        Vue.prototype.resetSetItem('isConnecting', false);
        if (router.history.current.path == '/exam/paper') {
            sessionStorage.setItem('routerPaperID', connection.connectionId)
        }
        if (router.history.current.path == '/home') {
            sessionStorage.setItem('routerListID', connection.connectionId)
        }
    });
    //重试连接失败后，调用关闭回调
    connection.onclose(error => {
        console.warn('connection', connection);
        Vue.prototype.resetSetItem('isConnecting', true);
        console.assert(connection.state === signalR.HubConnectionState.Disconnected);
        console.warn('重连失败', signalR);
    });

    // // 自定义开始函数
    async function start() {
        try {
            await connection.start();
            console.log("SignalR Connected.");
        } catch (err) {
            console.log(err);
            setTimeout(this.start(), 5000);
        }
    }
    connection.start().then(function () {
        let connectionList = []
        if (router.history.current.path == '/exam/paper' || router.history.current.path == '/exam/question') {
            sessionStorage.setItem('routerPaperID', connection.connectionId)
        }
        if (router.history.current.path == '/home') {
            sessionStorage.setItem('routerListID', connection.connectionId)
        }
        if (sessionStorage.getItem('routerListID')) {
            connectionList.push(sessionStorage.getItem('routerListID'))
        }
        if (sessionStorage.getItem('routerPaperID')) {
            connectionList.push(sessionStorage.getItem('routerPaperID'))
        }
        // connectionIdList.push(connection.connectionId)
        Vue.prototype.resetSetItem('isConnecting', false);
        if (router.history.current.path == '/home') {
            connection.invoke('SendOfflineNotifyMessage', connectionList, 'OfflineNotify', '账号在他处登录').catch(function (err) {
                return console.error(err.toString());
            });
        }
    }).catch(function (err) {
        console.assert(connection.state === signalR.HubConnectionState.Disconnected);
        console.log(err);
        Vue.prototype.resetSetItem('isConnecting', true);
        setTimeout(() => this.start(), 5000);
    });
    Vue.prototype.signalr = connection
}
export {
    // install方法的第一个参数是 Vue 构造器，第二个参数是一个可选的选项对象。
    init

}