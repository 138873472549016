/*
 * @Author: your name
 * @Date: 2021-09-22 10:02:54
 * @LastEditTime: 2022-03-22 15:58:43
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web-admin\src\api\settings.js
 */

import {
    axios
} from './../plugin/axios'
import {
    serverPrefix,
    loginPrefix
} from '../config'
export const _isDev = process.env.NODE_ENV === 'development'

export default {

    getNoSubmitList(params) {
        return axios.post(`/api/examservice/exam/list/user/no-submit`, params)
    },
    getConfig(params) {
        return axios.post(`/api/abp/application-configuration`, params)
    },
    getUserInfo(id) {
        return axios.get(`/api/base/user/${id}`)
    },
    getCopyright() {
        return axios.get(`/api/base/user/setting-ui-front`)
    },
    checkCode(requestId, code) {
        return axios.get(`/api/base/user/checkvalidcode?requestId=${requestId}&code=${code}`)
    },
    // getNewToken(url, values) {
    //     return axios.get(`${url}api/third/login/loginskip?token=${values}`)
    // },
    getNewToken(values,towhere,url) {
        return axios.get(`third/login/loginskip?token=${values}&towhere=${towhere}&requestUrl=${url}`)
    },
    userCreate() {
        if (_isDev) {
            return axios.post(`${serverPrefix}/api/logininfo/create`)
        } else {
            return axios.post(`${serverPrefix}/logininfo/create`)
        }
    },
    userUpdate() {
        if (_isDev) {
            return axios.post(`${serverPrefix}/api/logininfo/update`)
        } else {
            return axios.post(`${serverPrefix}/logininfo/update`)
        }
    },
    thirdLogin(params) {
        return axios.post(`${loginPrefix}/connect/singletoken`, params)

    },
    checkUserDetail(userName, phoneNumber, checkType) {
        if (checkType == 1) {
            return axios.get(`/api/base/user/check-user-bymobile?userName=${userName}&checkType=${checkType}`)
        } else {
            return axios.get(`/api/base/user/check-user-bymobile?userName=${userName}&phoneNumber=${phoneNumber}&checkType=${checkType}`)
        }
    },
    getCode(userName) {
        return axios.get(`/api/base/user/send-validatenum-bymobile?userName=${userName}`)
        // return axios.get(`/api/base/user/check-user-bymobile?userName=${userName}`)
    },
    checkCode(userName, validateNum) {
        return axios.get(`/api/base/user/check-validatenum-bymobile?userName=${userName}&validateNum=${validateNum}`)
        // return axios.get(`/api/base/user/check-user-bymobile?userName=${userName}`)
    },
    checkPassWord(passwordStr) {
        return axios.get(`/api/base/user/verify-password-format?passwordStr=${passwordStr}`)
    },
    resetPassWord(checkType, userNameStr, validNum, params) {
        return axios.post(`/api/base/user/update-user-byusername?checkType=${checkType}&userName=${userNameStr}&validNum=${validNum}`, params)
    },
    checkEmailDetail(userName, email, checkType) {
        if (checkType == 1) {
            return axios.get(`/api/base/user/check-user-byemail?userName=${userName}&checkType=${checkType}`)
        } else {
            return axios.get(`/api/base/user/check-user-byemail?userName=${userName}&email=${email}&checkType=${checkType}`)
        }
    },
    checkEmailCode(userName, validateNum) {
        return axios.get(`/api/base/user/check-validatenum-byemail?userName=${userName}&validateNum=${validateNum}`)
        // return axios.get(`/api/base/user/check-user-bymobile?userName=${userName}`)
    },
    getEmailCode(userName) {
        return axios.get(`/api/base/user/send-validatenum-byemail?userName=${userName}`)
        // return axios.get(`/api/base/user/check-user-bymobile?userName=${userName}`)
    },
    checkUser(userName) {
        return axios.get(`/api/base/user/check-userinfo?userName=${userName}`)
    }




}