/*
 * @Author: your name
 * @Date: 2021-11-03 14:51:35
 * @LastEditTime: 2022-03-25 17:55:22
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \web-pc\src\main.js
 */

import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import './plugin/element'
import './css/reset.scss'
import './css/fontSize/fontSize.css'
import './plugin/vant'
import router from "./plugin/router";
// import signalr from './plugin/signalr'

// Vue.use(signalr)
const echarts = require('echarts');
Vue.prototype.$echarts = echarts
// Vue.use($)
Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'isConnecting') {

    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);

        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);

        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal);
  }
}
Vue.prototype.resetSetItem('isConnecting', true);
import Router from 'vue-router'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
