<template>
    <div class="sidebar-modal">
        <template>
            <div :class="index == sidebarList.length - 1 && 'end'" class="sidebar-item" v-for="(item, index) in sidebarList" @click="getMethods(item.methodsName)" :key="index">
                <!-- <img src="@/assets/home/sidebar-message.png" alt="" /> -->
                <div v-if="index != 0">
                    <div class="sidebar-item-center" @click="visible = !visible">
                        <img class="sidebar-item-img" :src="item.url" alt="" />
                        <span class="red-dot" v-if="unreadTotal && index == 0">
                            {{ unreadTotal }}
                        </span>
                    </div>
                    <span>{{ item.text }}</span>
                </div>
                <div v-else>
                    <el-popover placement="left" popper-class="sidebar-popper" style="right: 80px" width="280" v-model="visible" trigger="manual">
                        <div class="no-message-modal" v-if="messageList.length == 0">
                            <img src="../../assets/home/noMessage.png" alt="" />
                            <div>暂时没有新消息</div>
                        </div>
                        <div v-else>
                            <div class="message-list" v-for="(item, index) in messageList" :key="index">
                                <div class="message-item" v-if="index < 5" @click="isRead(item)" :class="[index == messageList.length - 1 && 'end']">
                                    <div class="item-title">
                                        {{ item.title }}
                                    </div>
                                    <div class="item-content">{{ item.content }}</div>
                                    <div class="item-time">{{ moment(item.creationTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss') }}</div>
                                </div>
                                <div v-else>查看更多</div>
                            </div>
                        </div>
                        <div slot="reference">
                            <div class="sidebar-item-center" @click="visible = !visible">
                                <img class="sidebar-item-img" :src="item.url" alt="" />
                                <span class="red-dot" v-if="unreadTotal && index == 0">
                                    {{ unreadTotal }}
                                </span>
                            </div>
                            <span>{{ item.text }}</span>
                        </div>
                    </el-popover>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
const messageImg = require('../../assets/home/sidebar-message.png');
const pathImg = require('../../assets/home/sidebar-path.png');
import Api from '../../api/sidebar';
import moment from 'moment';

export default {
    data() {
        return {
            sidebarList: [
                {
                    url: messageImg,
                    text: '消息通知',
                    methodsName: '',
                },
                {
                    url: pathImg,
                    text: '返回顶部',
                    methodsName: 'topFunction',
                },
            ],
            unreadTotal: 0,
            visible: false,
            messageList: [],
        };
    },
    methods: {
        moment,
        getMethods(methodsName) {
            if (methodsName) {
                this[methodsName]();
            }
        },
        isRead(item) {
            let params = [];
            params.push(item.id);
            Api.readMessage(params)
                .then(result => {
                    console.warn(result);
                    this.requestUnreadMessageNum();
                    this.requestMessageList();
                    this.$forceUpdate();
                })
                .catch(err => {
                    console.warn(err);
                });
        },
        requestUnreadMessageNum() {
            Api.getUnreadMessageNum()
                .then(res => {
                    this.unreadTotal = res.data.total;
                    console.warn(res);
                })
                .catch(err => {
                    console.warn(err);
                });
        },
        requestMessageList() {
            let params = {
                dialogueType: '0',
                // sourceTypeList: ['exam_notify'],
                isRead: false,
                title: '',
                maxResultCount: 5,
                skipCount: 0,
            };
            Api.getMessageList(params)
                .then(res => {
                    this.messageList = res.data.items;
                    // moment().format('YYYY-MM-DD HH:mm:ss')
                    this.$forceUpdate();

                    console.warn(res);
                })
                .catch(err => {
                    console.warn(err);
                });
        },
        initMessage() {
            this.signalr.on('ReceiveSysMsg', function (res) {
                // console.log(res);
                let resData = JSON.parse(res);
                console.warn(resData);
            });
        },
        topFunction() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            this.visible = false
        },
    },
    mounted() {
        if (this.$route.path != '/login' && this.$route.path != '/' && this.$route.path != '/thirdLogin') {
            this.requestMessageList();
            this.requestUnreadMessageNum();
            this.initMessage();
        }
    },
};
</script>
<style lang="scss" scoped>
.no-message-modal {
    // width: 278px;
    height: 194px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #999999;
    line-height: 20px;
    // box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.04);
}
.message-list {
    .message-item {
        padding: 6px 30px;
        min-height: 110px;
        border-bottom: 1px solid #ebecf4;
        .item-title {
            padding: 6px 0;
            font-size: 14px;
            font-weight: 600;
            color: #000000;
        }
        .item-content {
            margin: 10px 0;
            font-size: 12px;
            font-weight: 400;
            color: #333333;
        }
        .item-time {
            font-size: 12px;
            text-align: left;
            color: #999999;
        }
        &.end {
            border-bottom: none !important;
        }
    }
}
.sidebar-modal {
    position: fixed;
    width: 80px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 80px;
    background: #ffffff;
    border-image: linear-gradient(148deg, #ffffff 1%, #ffffff 90%) 2 2;
    border-radius: 13px 0px 0px 13px;
    overflow: hidden;
    box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.04);
    z-index: 999;
    .sidebar-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50px;
        margin: 0 auto;
        height: 80px;
        border-bottom: 1px solid #e5e5e5;
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: #1e2046;
        line-height: 15px;

        .sidebar-item-center {
            position: relative;
            .sidebar-item-img {
                margin-bottom: 3px;
            }
            .red-dot {
                padding: 3px;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                right: 0;
                // top: -10px;
                top: -50%;
                // right: -50%;
                min-width: 14px;
                height: 16px;
                background: #ff6c75;
                border-radius: 50%;
                color: #ffffff;
            }
        }

        &.end {
            border-bottom: none;
            img {
                margin-bottom: 10px !important;
            }
        }
    }
}
</style>